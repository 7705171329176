import Site from "./domain/entities/site";
import { useLocation } from "react-router-dom";
import Supplier from "./domain/entities/supplier";
import AppRouter from "./presentation/router/AppRouter";
import SiteOptions from "./domain/entities/siteOptions";
import { useAuth } from "./presentation/providers/Auth0JWTProvider";
import useNotificationsList from "./presentation/hooks/Notifications/useNotificationsList";
import React, {
  createContext,
  ReactNode,
  useCallback,
  useState,
  useEffect,
  useReducer,
  useContext,
} from "react";
import _ from "lodash";
import i18n from "i18next";
import { Button, Flex, Text, Box, useToast } from "@chakra-ui/react";
import alertOctagon from "react-useanimations/lib/alertOctagon";
import UseAnimations from "react-useanimations";
import { useTranslation } from "react-i18next";
import { COLORS } from "./presentation/assets/theme/colors";


interface IAppContext {
  context: {
    topBarTitle?: ReactNode;
    site?: {
      details: Site;
      options?: SiteOptions;
    };
    supplier?: {
      details: Supplier;
    };
  };

  notificationContext: any;
  updateContext?: (attributes: Partial<IAppContext["context"]>) => void;
}

const AppContext = createContext<IAppContext | undefined>(undefined);

type Action = {
  type: "UPDATE_CONTEXT";
  payload: Partial<IAppContext["context"]>;
};

const reducer = (
  state: IAppContext["context"],
  action: Action
): IAppContext["context"] => {
  switch (action.type) {
    case "UPDATE_CONTEXT":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const App = () => {
  const { companyId, user } = useAuth();
  const [state, dispatch] = useReducer(reducer, {});
  const authenticated = !!companyId;
  const path = useLocation();
  const {t} = useTranslation();
  const [recentRoutes, setRecentRoutes] = useState<string[]>([]);
  const [wasDeployedNewVersion, setWasDeployedNewVersion] = useState<boolean>(false);
  const [previousVersion, setPreviousVersion] = useState<string | null>(
    localStorage.getItem('manifestVersion')
  );

  document.documentElement.lang = i18n.language;
  i18n.on('languageChanged', (lng) => document.documentElement.lang = lng)

  const toast = useToast()

  useEffect(() => {
    if (!previousVersion) {
      localStorage.setItem('manifestVersion', '');
    }
  
    const fetchManifestVersion = () => {
      try {
        const manifestLink = document.querySelector('link[rel="manifest"]');
        if (!manifestLink) return;
  
        const manifestHref = manifestLink.getAttribute('href');
        if (!manifestHref) return;
  
        const url = new URL(manifestHref, window.location.origin);
        const version = url.searchParams.get('v');
  
        if (version && version !== previousVersion) {
          localStorage.setItem('manifestVersion', version);
          setPreviousVersion(version);
          setWasDeployedNewVersion(true);
        }
      } catch (error) {
        console.error('Error fetching manifest version:', error);
      }
    };
  
    const intervalId = setInterval(fetchManifestVersion, 60000);
  
    return () => clearInterval(intervalId);
  }, []);
  
  

  const updateContext = useCallback(
    (attributes: Partial<IAppContext["context"]>) => {
      dispatch({ type: "UPDATE_CONTEXT", payload: attributes });
    },
    []
  );

  const notificationContext = useNotificationsList();

  const providerValue = {
    context: state,
    notificationContext,
    updateContext,
  };

  useEffect(() => {
    if (authenticated) {
      if (path.pathname.includes("/unread")) {
        notificationContext.refetchNotifications();
      }
      notificationContext.refreshCount();
    }
  }, [path]);

  useEffect(() => {
    if (authenticated) {
      notificationContext.setAuthenticated(authenticated);
    }
  }, [authenticated]);



  useEffect(() => {
    const smartsuppScript = document.createElement("script");
    smartsuppScript.id = "smartsupp-script";
    const widgetContainer = document.getElementById(
      "smartsupp-widget-container"
    );

    smartsuppScript.innerHTML = `
      var _smartsupp = _smartsupp || {};
      _smartsupp.key = '5515c1ee6ac350948fff3f9af35bce124e35c269';
      window.smartsupp||(function(d) {
        var s,c,o=smartsupp=function(){ o._.push(arguments)};o._=[];
        s=d.getElementsByTagName('script')[0];c=d.createElement('script');
        c.type='text/javascript';c.charset='utf-8';c.async=true;
        c.src='//www.smartsuppchat.com/loader.js?';s.parentNode.insertBefore(c,s);
      })(document);
      window.smartsupp('email', '${_.escape(user?.email)}');
      window.smartsupp('name', '${_.escape(user?.name)}');
      window.smartsupp('language', '${user?.language}');
      window.smartsupp('variables', {
       referrer: "${location.origin}${location.pathname}",
       history: "${recentRoutes}"
      });
    `;
    smartsuppScript.async = true;
    if (process.env.REACT_APP_SMARTSUPP_ENABLE === "true" && authenticated) {
      document.body.appendChild(smartsuppScript);
      setRecentRoutes((prevState) => {
        const newRoute = location.origin + location.pathname;
        if (prevState[0] !== newRoute) {
          const updatedRoutes = [newRoute, ...prevState];
          return updatedRoutes.slice(0, 5);
        } else {
          return prevState;
        }
      });
      if (widgetContainer && document.body.contains(widgetContainer)) {
        widgetContainer.style.visibility = "visible";
      }
    }
    return () => {
      // Cleanup: remove the script from DOM using the ref
      if (
        document.body.contains(smartsuppScript) &&
        widgetContainer &&
        document.body.contains(widgetContainer)
      ) {
        document.body.removeChild(smartsuppScript);
        widgetContainer.style.visibility = "hidden";
      }
    };
  }, [authenticated, user, location.pathname]);

  return (
    <AppContext.Provider value={providerValue}>
      {wasDeployedNewVersion && <Flex width={'full'} bgColor={COLORS.error} justifyContent="center" py={2} textColor={'white'} gap={2}>
        <UseAnimations
          animation={alertOctagon}
          size={25}
          autoplay={true}
          strokeColor={'white'}
        />
        <Text>{t('newVersionAvailable', {ns: 'common'})}</Text>
        <Text onClick={() => {
          setWasDeployedNewVersion(false);
          window.location.reload();
        }} textDecoration={'underline'} cursor={'pointer'}>
          {t('getNewVersion', {ns: 'common'})}
        </Text>
      </Flex>}
      <AppRouter />
    </AppContext.Provider>
  );
};

export const useApp = () => useContext(AppContext);
