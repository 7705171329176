import { Image, Text, View } from "@react-pdf/renderer";
import QRCode from "qrcode";
import { useTranslation } from "react-i18next";
import logo from "../../../../assets/images/home-logo.png";
import Badge from "../../../../domain/entities/badge";
import { styles } from "./badgeStyles";

type QrCodeBadgeBackProps = {
  badge: Badge;
};

const QrCodeBadgeBack = ({ badge: { site: {company, protocol, photo, stakeholder}, serial } }: QrCodeBadgeBackProps) => {
  const { t } = useTranslation("badges");
  const qrCodeDataURL = QRCode.toDataURL(serial);
  return (
    <View style={styles.badgeContainer}>
      <View style={styles.badgeHeaderContainer}>
        <View style={{display: "flex", flexDirection: "column", gap: 2}}>
          <View style={{display: "flex", flexDirection: "row", alignItems: "baseline"}}>
            <Text style={styles.badgeHeader}>{t("pdfBadgeDetails.protocolNumber")}</Text>
            <Text style={{fontSize: 10, fontWeight: 600}}>{protocol}</Text>
          </View>
          <View style={{display: "flex", flexDirection: "row", alignItems: "baseline"}}>
            <Text style={styles.badgeHeader}>{t("pdfBadgeDetails.stakeholder")}</Text>
            <Text style={{fontSize: 10, fontWeight: 600}}>{stakeholder ?? '-'}</Text>
          </View>
        </View>
        <View style={styles.logoContainer}>
          <Image style={styles.logo} src={logo} />
        </View>
      </View>
      <View style={photo ? styles.qrCodeContainer : styles.qrCodeOnlyContainer}>
        {photo && <Image style={styles.siteImage} src={photo} />}
        <Image style={styles.qrImage} src={qrCodeDataURL} />
      </View>
      <View style={styles.footerBanner}>
        <Text style={{fontSize: 10, fontWeight: 700}}>www.sikuro.it</Text>
      </View>
    </View>
  );
};

export default QrCodeBadgeBack;
