import React, { useEffect, useState } from "react";
import TagList from "../Common/TagList";
import { useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { FiAlertTriangle, FiPlus, FiSettings, FiUpload } from "react-icons/fi";
import { COLORS } from "../../assets/theme/colors";
import ContentLayout from "../../layout/ContentLayout";
import ActionBar from "../../components/Common/ActionBar";
import StatusBadge from "../../components/Common/StatusBadge";
import BaseModal from "../../components/Common/alerts/BaseModal";
import ActionBarItem from "../../components/Common/ActionBarItem";
import { BadgeType } from "../../../domain/entities/badgeType.enum";
import DeleteButton from "../../components/Common/table/DeleteButton";
import { BadgeStatus } from "../../../domain/entities/badgeStatus.enum";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Link,
  List,
  ListItem,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useMediaQuery,
} from "@chakra-ui/react";
import { ErrorBanner } from "../../components/Common/alerts/ErrorBanner";
import CreateBadgeModal from "../../components/Views/Badge/CreateBadgeModal";
import BadgesImportModal from "../../components/Views/Badge/BadgesImportModal";
import TableColumnHeader from "../../components/Common/table/TableColumnHeader";
import {
  GetBadgesFilters,
  LinkResourcesToBadgesResponse,
} from "../../../domain/repositories/badgeRepository";
import { useBadgesListViewModel } from "../../hooks/Badge/useBadgesListViewModel";
import { BadgeResourceType } from "../../../domain/entities/badgeResourceType.enum";
import ColumnFilterComponent from "../../components/Common/table/ColumnFilterComponent";
import RenderIf, {
  useHasPermissions,
} from "../../components/Permissions/RenderIf";
import { Permission } from "../../components/Permissions/Permissions";
import InfiniteTable from "../../components/Common/table/InfiniteTable";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import LinkBadgesToSiteResourcesModal from "../../components/Views/Badge/LinkBadgesToSiteResourcesModal";
import ImportLinkResourceBadgeModal from "../../components/Views/Badge/ImportLinkResourceBadgeModal";
import { ConfirmAlert } from "../Common/ConfirmAlert";
import { IoPersonRemoveSharp } from "react-icons/io5";
import { FaLink } from "react-icons/fa";
import SearchInput from "../Common/SearchInput";
import { Alert } from "../Common/Alert";

const CompanyBadgesView = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("badges");
  const [isTablet] = useMediaQuery("(max-width: 1300px)");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const {
    badges,
    getBadgesIsLoading,
    deleteBadge,
    hasNextPage,
    fetchNextPage,
    error,
    clearErrors,
    deleteBadgeIsLoading,
    errorCreate,
    setErrorCreate,
    filters,
    updateFilter,
    sort,
    setSort,
    tags,
    importBadges,
    importBadgesIsLoading,
    errorImport,
    setErrorImport,
    createBadge,
    createMultipleBadgeQR,
    createBadgeIsLoading,
    allTags,
    search,
    setSearch,
    resourceFilters,
    resourceSort,
    setResourceSort,
    availableResourcesQuery,
    resourceType,
    setResourceType,
    availableResourcesHasNextPage,
    availableResourcesFetchNextPage,
    updateResourcesFilter,
    availableResourcesLoading,
    setAvailableBadges,
    badgesCount,
    resourcesCount,
    setAvailableResources,
    availableBadgesQuery,
    availableBadgesIsLoading,
    availableBadgesFilters,
    updateAvailableBadgesFilter,
    availableBadgesSort,
    setAvailableBadgesSort,
    availableBadgesQueryHasNextPage,
    availableBadgesQueryFetchNextPage,
    linkBadgesToResources,
    importLinkResourcesBadges,
    importLinkError,
    setImportLinkError,
    totalBadgeAvailableCount,
    unlinkBadgesFromResources,
    setSearchBadges,
    successImport,
    setSuccessImport
  } = useBadgesListViewModel();

  const [createModal, setCreateModal] = useState(false);
  const [importModal, setImportModal] = useState(false);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState<string | false>(
    false,
  );
  const [linkBadgesModal, setLinkBadgesModal] = useState(false);
  const [importLinkModal, setImportLinkModal] = useState(false);
  const [deleteCandidateBadge, setDeleteCandidateBadge] = useState<string[]>(
    [],
  );
  const [selectedBadges, setSelectedBadges] = useState<string[]>([]);
  const [selectAllVisibleBadges, setSelectAllVisibleBadges] =
    useState<boolean>(false);
  const [selectAllPaginatedBadges, setSelectAllPaginatedBadges] =
    useState<boolean>(false);
  const [result, setResult] = useState<LinkResourcesToBadgesResponse>();

  const tableColumns: {
    field: keyof GetBadgesFilters;
    type: "text" | "select" | "tags";
    options?: Record<string, string>;
  }[] = [
    { field: "code", type: "text" },
    { field: "type", type: "select", options: BadgeType },
    { field: "serial", type: "text" },
    { field: "tagIds", type: "tags" },
    { field: "resourceType", type: "select", options: BadgeResourceType },
    { field: "resourceName", type: "text" },
    { field: "company", type: "text" },
    { field: "status", type: "select", options: BadgeStatus },
  ];

  const canViewBadgeDetails = useHasPermissions([
    Permission.Company_ShowBadgeDetails,
  ]);
  const openModalUploadLink = () => {
    setLinkBadgesModal(false);
    setImportLinkModal(true);
  };
  const toggleSelectAll = (value: boolean) => {
    setSelectAllVisibleBadges(value);
    if (!value) {
      setSelectedBadges([]);
      setSelectAllPaginatedBadges(false);
    } else {
      setSelectedBadges(badges.map((badge) => badge.id));
    }
  };
  const handleSelectionButton = () => {
    if (selectAllPaginatedBadges) {
      setSelectedBadges([]);
      setSelectAllVisibleBadges(false);
      setSelectAllPaginatedBadges(false);
    } else {
      setSelectAllPaginatedBadges(true);
    }
  };
  const toggleItem = (id: string) => {
    if (!selectedBadges.includes(id)) {
      setSelectedBadges([...selectedBadges, id]);
    } else {
      setSelectedBadges(selectedBadges.filter((i) => i !== id));
    }
  };

  const namespace =
    importLinkError !== "cannotLinkResourceToBadge" ? "common" : "errors";
  const templatePath = () => {
    switch (resourceType) {
      case BadgeResourceType.WORKER:
        return "/documents/TemplateImportLavoratori-Badge.xlsx";
      case BadgeResourceType.MACHINE:
        return "/documents/TemplateImportMacchinari-Badge.xlsx";
      case BadgeResourceType.VEHICLE:
        return "/documents/TemplateImportVeicoli-Badge.xlsx";
      default:
        return null;
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <ContentLayout
      action={
        <ActionBar>
          <RenderIf permissions={[Permission.Company_AddBadge]}>
            <ActionBarItem
              onClick={() => setCreateModal(true)}
              icon={FiPlus}
              description={t("createTitle")}
            />
          </RenderIf>
          <RenderIf permissions={[Permission.Company_LinkBadgeResource]}>
            <ActionBarItem
              onClick={() => {
                setResourceType(BadgeResourceType.WORKER);
                setAvailableBadges(true);
                setAvailableResources(true);
                setLinkBadgesModal(true);
              }}
              icon={AiOutlineUsergroupAdd}
              description={t("linkToResources")}
              bgColor={COLORS.sikuroBlue}
              color="white"
            />
          </RenderIf>
          <RenderIf permissions={[Permission.Company_UnlinkBadgeResource]}>
            <ActionBarItem
              onClick={() => {
                setDeleteCandidateBadge(selectedBadges);
              }}
              icon={IoPersonRemoveSharp}
              description={t("unlinkBadges")}
              disabledDescription={t("noBadgesSelected")}
              isDisabled={selectedBadges.length === 0}
            />
          </RenderIf>
          <RenderIf permissions={[Permission.Company_ImportBadge]}>
            <ActionBarItem
              onClick={() => setImportModal(true)}
              icon={FiUpload}
              color="white"
              bgColor={COLORS.sikuroBlue}
              description={t("importBadges")}
            />
          </RenderIf>
        </ActionBar>
      }
    >
      <Flex
        flex={1}
        h="100%"
        w="100%"
        paddingTop={10}
        paddingLeft={10}
        paddingRight={3}
        textAlign="center"
        flexDirection="column"
        alignItems="start"
        justifyContent="start"
      >
        {error && <ErrorBanner text={error.toString()} onClose={clearErrors} />}
        <Flex justifyContent={"space-between"} alignItems="center" w={"100%"}>
          <Box textAlign={"start"}>
            <Text
              textColor={COLORS.sikuroBlue}
              fontSize={20}
              fontWeight={"bold"}
            >
              {t("title")}
            </Text>
            <Text>{t("subtitle")}</Text>
          </Box>
          <SearchInput onSearch={setSearchBadges} />
        </Flex>
        <Flex
          flexDirection={"column"}
          alignItems={"start"}
          border="1px solid"
          borderColor="gray.300"
          borderRadius="10px"
          width={"calc(100vw - 175px)"}
          marginTop={5}
          overflow={"auto"}
        >
          <InfiniteTable
            autosize={true}
            tableId="company-badges-table"
            isCheckboxTable={true}
            infiniteScroll={{
              dataLength: badges.length,
              hasNextPage,
              fetchNextPage,
            }}
            isLoading={getBadgesIsLoading}
            emptyText={
              Object.keys(filters).length > 0
                ? t("noBadgesFound", { ns: "badges" })
                : t("noCompanyBadges", { ns: "badges" })
            }
          >
            <Thead width={"100%"}>
              <Tr>
                <Th key={"selectAllCheckbox"} width={10}>
                  <Checkbox
                    borderColor={"gray.500"}
                    isChecked={selectAllVisibleBadges}
                    onChange={() => toggleSelectAll(!selectAllVisibleBadges)}
                  ></Checkbox>
                </Th>
                {tableColumns.map((column) => (
                  <Th key={column.field} width={isTablet ? "200px" : "100%"}>
                    <TableColumnHeader
                      text={t(`columns.${column.field}`)}
                      filter={{
                        component: (
                          <ColumnFilterComponent
                            type={column.type}
                            value={filters[column.field]}
                            updateFilter={(value) => {
                              updateFilter(
                                column.field,
                                value as string | string[],
                              );
                            }}
                            tags={tags}
                            selectOptions={column.options}
                            namespace="badges"
                          />
                        ),
                        isActive: !!(Array.isArray(filters[column.field])
                          ? filters[column.field][0]
                          : filters[column.field]),
                      }}
                      sort={
                        column.type !== "tags" && {
                          handler: (direction) =>
                            setSort({ field: column.field, direction }),
                          direction:
                            sort && sort.field === column.field
                              ? sort.direction
                              : null,
                        }
                      }
                    />
                  </Th>
                ))}
                <Th width="40px" />
              </Tr>
            </Thead>
            <Tbody>
              <Tr width={"100%"}>
                {selectAllVisibleBadges && (
                  <Th
                    colSpan={tableColumns.length + 1}
                    backgroundColor={"gray.100"}
                  >
                    <Text textAlign="center" mx="auto">
                      {!selectAllPaginatedBadges && t("badgesSelectedVisible")}
                      {selectAllPaginatedBadges &&
                        t("badgesSelectedNotVisible", { count: badgesCount })}

                      {hasNextPage && (
                        <Button
                          mt="10px"
                          ml="4px"
                          colorScheme="blue"
                          variant="link"
                          onClick={() => handleSelectionButton()}
                        >
                          {t(
                            selectAllPaginatedBadges
                              ? "clearSelection"
                              : "badgesSelectAll",
                            { ns: "badges" },
                          )}
                        </Button>
                      )}
                    </Text>
                  </Th>
                )}
              </Tr>
              {badges.map((badge) => (
                <Tr
                  key={badge.id}
                  onClick={() => {
                    canViewBadgeDetails &&
                      navigate(`/company/badges/${badge.id}`);
                  }}
                  width={"100%"}
                  sx={{ cursor: canViewBadgeDetails && "pointer" }}
                >
                  <Td width={10} onClick={(e) => e.stopPropagation()}>
                    <Checkbox
                      borderColor={"gray.500"}
                      isChecked={
                        selectedBadges
                          ? selectedBadges?.includes(badge?.id)
                          : false
                      }
                      onChange={(e) => {
                        toggleItem(badge?.id);
                        e.stopPropagation();
                      }}
                      marginRight={3}
                    ></Checkbox>
                  </Td>
                  <Td width={isTablet ? "200px" : "100%"}>{badge.code}</Td>
                  <Td width={isTablet ? "200px" : "100%"}>{t(badge.type)}</Td>
                  <Td width={isTablet ? "200px" : "100%"}>
                    {badge.serial ? badge.serial : "-"}
                  </Td>
                  <Td width={isTablet ? "200px" : "100%"}>
                    <TagList tags={badge.tags} />
                  </Td>
                  <Td width={isTablet ? "200px" : "100%"}>
                    {badge.resource?.type ? t(badge.resource.type) : "-"}
                  </Td>
                  <Td width={isTablet ? "200px" : "100%"}>
                    {badge.resource?.name ? t(badge.resource.name) : "-"}
                  </Td>
                  <Td width={isTablet ? "200px" : "100%"}>
                    {badge.resource?.companyName
                      ? badge.resource.companyName
                      : "-"}
                  </Td>
                  <Td width={isTablet ? "200px" : "100%"}>
                    <StatusBadge value={badge.status} />
                  </Td>
                  <Td p={0} width="40px">
                    <RenderIf permissions={[Permission.Company_AddBadge]}>
                      <DeleteButton
                        onClick={() => setConfirmDeleteModal(badge.id)}
                      />
                    </RenderIf>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </InfiniteTable>
        </Flex>
      </Flex>

      {createModal && (
        <CreateBadgeModal
          isLoading={createBadgeIsLoading}
          onClose={() => setCreateModal(false)}
          onConfirm={createBadge}
          createMultipleBadgeQR={createMultipleBadgeQR}
          allTags={allTags}
        />
      )}
      {linkBadgesModal && (
        <LinkBadgesToSiteResourcesModal
          onClose={() => {
            setResourceType(null);
            setLinkBadgesModal(false);
          }}
          onConfirm={async (
            badgeIds,
            resourceIds,
            selectAllResources,
            selectedResourcesNumber,
            siteResourcesFilters,
            selectAllBadges,
            selectedBadgesNumber,
            availableBadgesFilters,
            automaticLinking,
          ) => {
            const result = await linkBadgesToResources({
              badgeIds,
              resourceIds,
              selectAllResources,
              selectedResourcesNumber,
              siteResourcesFilters,
              selectAllBadges,
              selectedBadgesNumber,
              availableBadgesFilters,
              automaticLinking,
            });
            setResult(result);
            setResourceType(null);
          }}
          availableBadges={availableBadgesQuery}
          availableBadgesIsLoading={availableBadgesIsLoading}
          availableBadgesCount={totalBadgeAvailableCount}
          availableBadgesTags={[]}
          availableBadgesHasNextPage={availableBadgesQueryHasNextPage}
          availableBadgesFetchNextPage={availableBadgesQueryFetchNextPage}
          availableBadgesFilters={availableBadgesFilters}
          updateAvailableBadgesFilter={updateAvailableBadgesFilter}
          availableBadgesSort={availableBadgesSort}
          setAvailableBadgesSort={setAvailableBadgesSort}
          siteResources={availableResourcesQuery as unknown as any}
          siteResourcesIsLoading={availableResourcesLoading}
          registeredSiteResourceCount={resourcesCount}
          resourceType={resourceType}
          setResourceType={(opt) => setResourceType(opt)}
          siteResourcesFilters={resourceFilters}
          updateSiteResourcesFilter={updateResourcesFilter as unknown as any}
          siteResourcesSort={resourceSort}
          setSiteResourcesSort={setResourceSort}
          siteResourcesHasNextPage={availableResourcesHasNextPage}
          siteResourcesFetchNextPage={availableResourcesFetchNextPage}
          openFileImport={openModalUploadLink}
          linkingIsLoading={false}
          search={search}
          setSearch={setSearch}
        />
      )}

      {!importLinkError && importLinkModal && (
        <ImportLinkResourceBadgeModal
          onClose={() => setImportLinkModal(false)}
          onConfirm={importLinkResourcesBadges}
          resourceType={resourceType}
        />
      )}

    {successImport && (
        <Alert
          title={t("warning", { ns: "common" })}
          message={t('importedLinkTitle', { ns: "badges" })}
          onClose={() => {
            setSuccessImport(false);
          }}
          variant="info"
        />
      )}

      {importModal && (
        <BadgesImportModal
          error={errorImport}
          onClose={() => {
            setImportModal(false), setErrorImport(null);
          }}
          onConfirm={importBadges}
        />
      )}

      {confirmDeleteModal && (
        <BaseModal
          type="warning"
          isLoading={deleteBadgeIsLoading}
          onClose={() => setConfirmDeleteModal(false)}
          onConfirm={async () => await deleteBadge(confirmDeleteModal)}
        >
          {t("deleteMessage")}
        </BaseModal>
      )}

      {deleteCandidateBadge && deleteCandidateBadge.length > 0 && (
        <ConfirmAlert
          variant="warning"
          title={t("warning", { ns: "common" })}
          message={
            deleteCandidateBadge.length > 1
              ? t("askUnlinkBadgesFromResources")
              : t("askUnlinkBadgeFromResource")
          }
          onCancel={() => setDeleteCandidateBadge(undefined)}
          onConfirm={() => {
            unlinkBadgesFromResources({
              badgeIds: deleteCandidateBadge,
              selectAll: selectAllPaginatedBadges,
            });
            setDeleteCandidateBadge(undefined);
            setSelectedBadges([]);
            setSelectAllVisibleBadges(false);
            setSelectAllPaginatedBadges(false);
          }}
        />
      )}

      {errorCreate && (
        <BaseModal
          type="warning"
          title={t(
            errorCreate.includes("code")
              ? "badgeCodeDuplicateTitle"
              : "badgeSerialDuplicateTitle",
            { ns: "badges" },
          )}
          onClose={() => setErrorCreate(undefined)}
          onConfirm={() => setErrorCreate(undefined)}
          onConfirmLabel={t("close", { ns: "common" })}
          onCancel={false}
        >
          <Text>
            <Trans
              i18nKey={
                errorCreate.includes("code")
                  ? "badgeCodeDuplicateDesc"
                  : "badgeSerialDuplicateDesc"
              }
              ns="badges"
            />
          </Text>
        </BaseModal>
      )}
      {importLinkError && (
        <Alert
          variant="warning"
          title={t("warning", { ns: "common" })}
          message={ <Text>
            <Trans
              i18nKey={importLinkError}
              ns={namespace}
              components={{
                a: (
                  <Link
                    sx={{ color: COLORS.sikuroBlue }}
                    href={templatePath()}
                  />
                ),
              }}
            />
          </Text>}
          onClose={() => {
            setResourceType(null);
            setImportLinkError(null);
            setImportLinkModal(false);
          }}
        />
      )}
      {result && (
        <BaseModal
          type="warning"
          title={t("linkingResult", { ns: "badges" })}
          onClose={() => setResult(null)}
          onConfirm={() => setResult(null)}
          onConfirmLabel={t("close", { ns: "common" })}
          onCancel={false}
        >
          <Flex gap="4" pt="4" alignItems="center">
            <FiSettings color={COLORS.sikuroBlue} />
            {`${t("selectedBadgesNumber", { ns: "badges" })}: ${result.selectedBadgesNumber}`}
          </Flex>

          <Flex gap="4" pt="4" alignItems="center">
            <FaLink color={COLORS.sikuroBlue} />
            {`${t("linkedBadgesNumber", { ns: "badges" })}: ${result.linkedBadgesNumber}`}
          </Flex>

          {(result.errors.resources.length > 0 ||
            result.errors.badges.length > 0) && (
            <Box py="4">
              <Flex gap="4" alignItems="center">
                <FiAlertTriangle color={COLORS.red} />
                {`${t("linkingErrors", { ns: "badges" })}`}
              </Flex>

              <Text py="4">{`${t("resources", { ns: "badges" })}:`}</Text>
              <Divider />
              <List>
                {result.errors.resources.map((b) => (
                  <ListItem key={b}>{b}</ListItem>
                ))}
              </List>

              <Text py="4">{`${t("badges", { ns: "badges" })}:`}</Text>
              <Divider />
              <List>
                {result.errors.badges.map((b) => (
                  <ListItem key={b}>{b}</ListItem>
                ))}
              </List>
            </Box>
          )}
        </BaseModal>
      )}
    </ContentLayout>
  );
};

export default CompanyBadgesView;
