import { Image, Text, View } from "@react-pdf/renderer";
import { ReactNode } from "react";
import Badge from "../../../../domain/entities/badge";
import logo from "../../../../assets/images/logoSikuro.png";
import { styles } from "./badgeStyles";
import { useTranslation } from "react-i18next";

type FrontBadgeContainerProps = {
  children: ReactNode | ReactNode[];
  badge: Badge;
  photo: string;
};
const FrontBadgeContainer = ({ children, badge, photo }: FrontBadgeContainerProps) => {
  const { t } = useTranslation("badges");
  return (
    <View style={styles.badgeContainer}>
      <View style={styles.badgeHeaderContainer}>
        <Text style={[styles.badgeHeader, {textTransform: "uppercase"}]}>{badge.site.name}</Text>
      </View>
      <View style={styles.mainContainer}>
        <View style={styles.photoContainer}>
          <Image style={styles.photo} src={photo} />
          <View style={styles.badgeNumber}>
            <Text>{t("pdfBadgeDetails.badgeNumber", {badgeNumber: badge.code})}</Text>
          </View>
        </View>
        <View style={styles.badgeDetailsContainer}>
          <View style={{minHeight: "75%"}}>
            {children}
          </View>
          <View style={[styles.logoContainer, {marginRight: 16}]}>
            <Image style={styles.logo} src={logo} />
          </View>
        </View>
      </View>
      <View style={styles.footerBanner}>
        <Text>{t("pdfBadgeDetails.footer")}</Text>
      </View>
    </View>
  )
};

export default FrontBadgeContainer;
