import { injectable } from 'tsyringe';
import User from '../../domain/entities/user';
import UsersRepository, { UserEmailsFilters } from "../../domain/repositories/usersRepository";
import { GetUsersFilter } from '../../domain/repositories/filters';
import Permission from '../../domain/entities/permission';
import { ApiService } from '../utilities/apiService';
import { SortMeta } from "../../domain/entities/interfaces/paginatedResults";
import { UserEmail } from "../../domain/entities/userEmail";
import { dateIntervals } from "../utilities/filters";

@injectable()
class ServerUsersRepository implements UsersRepository {
	constructor(private apiService: ApiService) {}

	private apiBaseURL = process.env.REACT_APP_SERVER_API_ENDPOINT;

	// Public.
	async getUsers(companyId: string, filters?: GetUsersFilter, 		sort?: SortMeta, pageParam?: number): Promise<Array<User>> {
		const { lastLogin, email, name, state } = filters ?? {};
		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: '25',
			...sort,
			...dateIntervals(lastLogin),
			...name && { name },
			...email && { email },
			...state && { state },
		});

		const url = `${this.baseURL(companyId)}/users?${params}`;
		const response = await this.apiService.fetchWithToken(url);
		const { results } = await response.json();
		return results ?? [];
	}

	async getUser(companyId: string, userId: string): Promise<User> {
		const response = await this.apiService.fetchWithToken(`${this.baseURL(companyId)}/users/${userId}`);
		return response.json();
	}

	async deleteUser(companyId: string, userId: string): Promise<boolean> {
		const response = await this.apiService.fetchWithToken(`${this.baseURL(companyId)}/users/${userId}`, { method: 'DELETE' });
		return Promise.resolve(response.ok);
	}

	async createUser(companyId: string, name: string, email: string, roleId: string): Promise<User> {
		const payload = {
			name: name,
			email: email,
			roleId: roleId,
		};

		try {
			const response = await this.apiService.fetchWithToken(`${this.baseURL(companyId)}/users`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(payload),
			});
			if (!response.ok) {
				if (response.status === 403) {
					const message = 'userAlreadyRegistered';
					return Promise.reject(new Error(message));
				}
			}
			return await response.json();
		} catch (e) {
			const message = e.message || 'Unknown error occurred';
			return Promise.reject(new Error(message));
		}
	}

	async updateUser(companyId: string, id: string, user: User): Promise<User> {
		const payload = {
			name: user.name,
			email: user.email,
			state: user.state,
			roleId: user.role.id,
			language: user.language,
		};

		const response = await this.apiService.fetchWithToken(`${this.baseURL(companyId)}/users/${id}`, {
			method: 'PUT',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(payload),
		});

		return response.json();
	}

	async updateUserImage(companyId: string, userId: string, image: File): Promise<User> {
		const request = new FormData();
		request.append('photo', image);

		const url = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/users/${userId}/photo`;
		const response = await this.apiService.fetchWithToken(url, { method: 'POST', body: request });
		return await response.json();
	}

	async getGeneralPermissions(companyId: string, userId: string): Promise<Permission[]> {
		const response = await this.apiService.fetchWithToken(`${this.apiBaseURL}/auth/users/${userId}/permissions?tenant=${companyId}`);

		const { results } = await response.json();
		return results ?? [];
	}

	async getSitePermissions(companyId: string, userId: string, siteId: string): Promise<Permission[]> {
		const response = await this.apiService.fetchWithToken(
			`${this.apiBaseURL}/auth/users/${userId}/sites/${siteId}/permissions?tenant=${companyId}`,
		);

		const { results } = await response.json();
		return results ?? [];
	}

	async userEmailAvailable(email: string): Promise<boolean> {
		const response = await this.apiService.fetchWithToken(`${this.apiBaseURL}/users/email-available?email=${email}`);
		const { result } = await response.json();
		return result;
	}

	async companyEmailAvailable(email: string): Promise<boolean> {
		const response = await this.apiService.fetchWithToken(`${this.apiBaseURL}/companies/count-by-email?email=${email}`);
		const { result } = await response.json();
		//NM:If the result is zero, I will direct the user to the registration page since no company has registered with this email;
		// otherwise, I will direct them to the login page
		return result === 0;
	}

	async getBusinessTypes(): Promise<string[]> {
		const response = await this.apiService.fetchWithToken(`${this.apiBaseURL}/available-business-types`);
		const { result } = await response.json();
		return result;
	}

	async renewInvitation(companyId: string, invitationToken: string, siteId?: string): Promise<void> {
		try {
			const url = siteId
				? `${this.baseURL(companyId)}/sites/${siteId}/token/${invitationToken}/renew`
				: `${this.baseURL(companyId)}/users/token/${invitationToken}/renew`;
			const response = await this.apiService.fetchWithToken(url, {
				method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
			});
			if (!response.ok) {
				if (response.status === 425) {
					const error = 'tooEarly';
					return Promise.reject(new Error(error));
				}
				if (response.status === 117) {
					const error = 'invitationAlreadyAccepted';
					return Promise.reject(new Error(error));
				} else {
					const message = 'generic';
					return Promise.reject(new Error(message));
				}
			}
		} catch (e) {
			const message = e.message || 'Unknown error occurred';
			return Promise.reject(new Error(message));
		}
	}
	async getUserEmails(companyId: string, userId: string,   filterEmails?: UserEmailsFilters,	sort?: SortMeta, page?: number): Promise<UserEmail[]> {
		const {...restFilter } = filterEmails || {};
		const params = new URLSearchParams({
			page: page.toString(),
			perPage: String(25),
			...restFilter,
			...sort,
		});
		const response = await this.apiService.fetchWithToken(`${this.apiBaseURL}/users/emails?${params.toString()}`, { method: 'GET' });
		const { results } = await response.json();
		return  results;
	}

	async removeUserEmail(companyId: string, userId: string, emailId: string): Promise<void> {
		await this.apiService.fetchWithToken(`${this.apiBaseURL}/users/emails/${emailId}`, { method: 'DELETE' });
	}

	async updateUserEmail(companyId: string, userId: string, email: UserEmail): Promise<UserEmail> {
		try {
			const response = await this.apiService.fetchWithToken(`${this.apiBaseURL}/users/emails/${email.id}`, {
				method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ ...email, state: 'primary' }),
			});
			if (!response.ok) {
				if (response.status === 403) {
					const message = 'emailAlreadyExist';
					return Promise.reject(new Error(message));
				}
			}
			return await response.json();
		} catch (e) {
			const message = e.message || 'Unknown error occurred';
			return Promise.reject(new Error(message));
		}
	}
	async createUserEmail(companyId: string, userId: string, email: UserEmail): Promise<UserEmail> {
		try {
			const response = await this.apiService.fetchWithToken(`${this.apiBaseURL}/users/emails`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({email: email.email  }),
			});
			if (!response.ok) {
				if (response.status === 409) {
					const message = 'emailAlreadyExist';
					return Promise.reject(new Error(message));
				}
			}
			return await response.json();
		} catch (e) {
			const message = e.message || 'Unknown error occurred';
			return Promise.reject(new Error(message));
		}
	}

	// Internal.
	private baseURL = (companyId: string): string => {
		return `${this.apiBaseURL}/companies/${companyId}`;
	};
}

export default ServerUsersRepository;
