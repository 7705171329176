import {
  Flex,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { HiOutlineLockClosed, HiOutlineLockOpen } from "react-icons/hi2";
import Document from "../../../../domain/entities/document";
import FileEntity from "../../../../domain/entities/file";
import { SortMeta } from "../../../../domain/entities/interfaces/paginatedResults";
import { ResourceDocumentEvaluationState } from "../../../../domain/entities/resourceDocumentEvaluationState.enum";
import Site from "../../../../domain/entities/site";
import Tag from "../../../../domain/entities/tag";
import { GetSitesFilter } from "../../../../domain/repositories/siteRepository";
import {
  formatDateBasedOnLanguage,
  getDateFormat,
  processUploadedFiles,
} from "../../../../utils";
import { COLORS } from "../../../assets/theme/colors";
import { DocumentCommunicationHook } from "../../../hooks/Document/useDocumentCommunicationVIewModel";
import { UseDocumentInfo } from "../../../hooks/Document/useDocumentInfo";
import { UseDocuments } from "../../../hooks/Document/useDocuments";
import { UseDownloader } from "../../../hooks/Document/useDocumentsFilesDownloader";
import { UpdateFilter } from "../../../hooks/Site/useSiteResourceDetailViewModel";
import { Alert } from "../../../screens/Common/Alert";
import { ConfirmAlert } from "../../../screens/Common/ConfirmAlert";
import TagList from "../../../screens/Common/TagList";
import BaseModal from "../../Common/alerts/BaseModal";
import CommentButton from "../../Common/CommentButton";
import AddFileButton from "../../Common/table/AddFileButton";
import ColumnFilterComponent from "../../Common/table/ColumnFilterComponent";
import DeleteButton from "../../Common/table/DeleteButton";
import HistoryButton from "../../Common/table/HistoryButton";
import InfiniteTable from "../../Common/table/InfiniteTable";
import SeeFileButton from "../../Common/table/SeeFileButton";
import TableColumnHeader from "../../Common/table/TableColumnHeader";
import RenderIf, { useHasPermissions } from "../../Permissions/RenderIf";
import { DocumentDetailModal } from "../common/DocumentDetailModal";
import DocumentHistoryModal from "../common/DocumentHistoryModal";
import SelectSitesView from "../common/SelectSitesView";
import { ResourceDocumentsPermissions } from "../common/SiteResourceDocuments";
import ExpirationIcon from "./ExpirationIcon";
import { PermissionCheck } from "../../../providers/Auth0JWTProvider";
import { parse } from "date-fns";
import GeneralDocumentsDnDView from "./GeneralDocumentsDndView";
import { useLocation, useParams } from "react-router-dom";
import { LuFileSearch2 } from "react-icons/lu";
import { Permission } from "../../Permissions/Permissions";
import useSiteDetailsViewModel from "../../../hooks/Site/useSiteDetailsViewModel";

const documentStatesMap = {
  [ResourceDocumentEvaluationState.AVAILABLE]: COLORS.sikuroBlue,
  [ResourceDocumentEvaluationState.NOT_APPLICABLE]: COLORS.yellow,
  [ResourceDocumentEvaluationState.NOT_AVAILABLE]: "red",
  [ResourceDocumentEvaluationState.UNDEFINED]: "gray.300",
  [ResourceDocumentEvaluationState.UNEVALUATED]: "gray.300",
};

export interface UpdateDocumentOptions {
  isPublic?: boolean;
  expiresAt?: Date;
  noExpiration?: boolean;
  siteIds?: string[];
  selectAll?: boolean;
}

export type DocumentsDataTableProps = UseDocumentInfo &
  Omit<
    UseDocuments,
    "createDocument" | "createPropagableDocuments" | "deleteDocument"
  > & {
    columns?: {
      field: string;
      filterType: "text" | "tags" | "date-range" | "select";
      label: string;
    }[];
    tags: Tag[];
    deleteDocument: (documentId: string) => void;
    deleteDocumentIsLoading: boolean;
    downloader: UseDownloader;
    documentsHasNextPage: boolean;
    documentsFetchNextPage: () => void;
    isReadOnly?: boolean;
    resourceSites?: Site[];
    resourceHasSites?: (action: string, documentTypeId?: string) => Promise<boolean>;
    isFetchingSites?: boolean;
    filter?: any;
    setFilter?: () => void;
    fromResource?: boolean;
    addFileToResourceDocument?: (
      documentId: string,
      file: FileEntity,
      siteIds: string[],
      selectAll: boolean,
      filters?: GetSitesFilter
    ) => Promise<void>;
    repropagateFileResourceDocument?: (
      documentId: string,
      siteIds: string[],
      selectAll: boolean,
      fileIds: string[],
      selectAllFiles: boolean,
      filters?: GetSitesFilter
    ) => Promise<void>;
    showExpiresAt?: boolean;
    filterResourceSites?: GetSitesFilter;
    setFilterResourceSites?: (filter: GetSitesFilter) => void;
    updateFilterResourceSites?: UpdateFilter;
    setSortResourceSites?: (sort: SortMeta) => void;
    sortResourceSites?: SortMeta;
    fromSite?: boolean;
    siteGeneralDocument?: boolean;
    communicationProps?: DocumentCommunicationHook;
    isWorkingSite?: boolean;
    autosize?: boolean;
    isFetchingResourceSites?: boolean;
    documentsFetching?: boolean;
    setErrorMsg?: (value) => void;
    setSuccessMsg?: (value) => void;
    permissions: ResourceDocumentsPermissions;
    hasSitesNextPage?: boolean;
    fetchSitesNextPage?: () => void;
    siteCount?: number;
    showRepropagate?: boolean
    success?: string,
    setSuccess?: (value: string) => void
  };

function DocumentsTable({
  documents,
  setDocumentTypeId,
  documentTypeId,
  refetchDocuments,
  documentsHasNextPage,
  documentsFetchNextPage,
  documentsLoading,
  columns,
  filterDocuments: defaultFilterDocuments,
  updateFilterDocuments,
  sortDocuments,
  setSortDocuments,
  tags,
  documentFiles,
  deleteDocumentFile,
  setHookDocumentId,
  addFileToDocument,
  documentFileLogs,
  documentActionLogs,
  documentLogsFetching,
  documentFileLogsFetching,
  documentFilesFetching,
  filterDocumentLog,
  updateFilterDocumentLog,
  sortDocumentLog,
  setSortDocumentLog,
  deleteDocument,
  deleteDocumentIsLoading,
  updateDocument,
  evaluateDocument,
  repropagateEvaluationDocument,
  repropagateFileDocument,
  setSuccessMessage,
  successMessage,
  updateFile,
  downloader, // May be used in future iterations to enable dowloading of all files of all docs of the current context
  setErrorMsg,
  errorMsg,
  successMsg,
  setSuccessMsg,
  isAddingFile,
  isReadOnly,
  resourceSites,
  isFetchingSites,
  fromResource,
  addFileToResourceDocument,
  repropagateFileResourceDocument,
  communicationProps,
  filterResourceSites,
  setFilterResourceSites,
  updateFilterResourceSites,
  setSortResourceSites,
  sortResourceSites,
  isFetchingResourceSites,
  handleDocumentTypeId,
  setEnableGetFileLogs,
  setEnableGetDocLogs,
  fileLogsHasNextPage,
  fileLogsFetchNextPage,
  docLogsHasNextPage,
  docLogsFetchNextPage,
  fromSite,
  deleteIsLoading,
  renameModel,
  refetchRenameModel,
  siteGeneralDocument,
  isWorkingSite,
  autosize,
  documentSites,
  hasSites,
  resourceHasSites,
  filterSites,
  setFilterSites,
  updateFilterSites,
  setSortSites,
  sortSites,
  documentsFetching,
  permissions,
  hasSitesNextPage,
  fetchSitesNextPage,
  siteCount,
  onReorder,
  generalDocumentsLoading,
  showRepropagate,
  success,
  setSuccess
}: DocumentsDataTableProps) {
  const location = useLocation();
  const { siteId } = useParams();
  const { t } = useTranslation("documents");
  const toast = useToast();
  const [showDocumentInfoModal, setShowDocumentInfoModal] = useState<
    false | { documentId: string; tab: 0 | 1 }
  >(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<
    false | string
  >(false);
  const [showConfirmUploadeModal, setShowConfirmUploadModal] =
    useState<boolean>(false);
  const [documentId, setDocumentId] = useState<string>();
  const [uploadData, setUploadData] = useState<{
    id: string;
    documentName: string;
    files: FileList;
  }>();
  const [droppedFile, setDroppedFile] = useState<FileEntity>();
  const [showSites, setShowSites] = useState<boolean>(false);
  const [isDraggingOverRow, setIsDraggingOverRow] = useState(null);
  const [showDocumentDetail, setShowDocumentDetail] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number>();
  const [action, setAction] = useState<"add" | "delete">();
  const [selectAllSites, setSelectAllSites] = useState(false);

  const hasCsePermission = useHasPermissions([Permission.Sites_CseShowSites, Permission.Worksite_CseShowWorkingSites], PermissionCheck.Some);
  const siteHook = useSiteDetailsViewModel(siteId);
  const canDownloadFiles = useHasPermissions(permissions.downloadAll, PermissionCheck.All);

  const defaultColumns = [
    { field: "name", filterType: "text" as const, label: t("name") },
  ];

  const tableColumns = columns ?? defaultColumns;

  const filterDocuments = defaultFilterDocuments ?? {
    name: "",
    tags: [],
  };

  const canAddFile =
    useHasPermissions(permissions.addFile, PermissionCheck.All) && !isReadOnly;

  // Handle drag&drop start
  const handleDragIn = (index: string) => {
    if (canAddFile) {
      setIsDraggingOverRow(index);
      if (!toast.isActive("uploadToast")) {
        toast({ id: "uploadToast", description: t("dropDocument") });
      }
    }
  };

  const handleDrop = async (
    document: Document,
    e: React.DragEvent<HTMLTableRowElement>,
  ): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();
    if (canAddFile) {
      setIsDraggingOverRow(null);
      await handleDocumentTypeId(document.type.id);
      if (e?.dataTransfer?.files[0]) {
        setDocumentId(document.id);
        handleFileDrop(document, e.dataTransfer.files);
      }
    } else {
      toast({
        id: "uploadToast",
        description: t("noPermission", { ns: "documents" }),
        status: "error",
      });
    }
  };

  const handleFileDrop = async (
    document: Document,
    files: FileList | null,
  ): Promise<void> => {
    if (!files) {
      return;
    }
    const uploadData = {
      id: document?.id,
      documentName: document.type.name,
      files: files,
    };
    toast.closeAll();
    setUploadData(uploadData);
    setIsDraggingOverRow(null);
    const hasSiteItems = fromSite
      ? null
      : fromResource
        ? await resourceHasSites("add", document.type.id)
        : await hasSites("add", document.type.id);
    if (hasSiteItems) {
      setShowSites(true);
    } else {
      setShowConfirmUploadModal(true);
    }
  };

  const handleAddFile = async (
    documentId?: string,
    siteIds?: string[],
    file?: FileEntity,
    selectAll?: boolean,
  ) => {
    const fileList = file ?? processUploadedFiles(uploadData.files);
    const siteIdsList = !selectAllSites ? siteIds : [];
    if (fromResource) {
      await addFileToResourceDocument(documentId, fileList, siteIdsList, selectAll, filterResourceSites );
    } else {
      setShowConfirmUploadModal(false);
      await addFileToDocument(documentId, fileList, siteIdsList, null,  selectAll, filterSites);
      refetchDocuments();
    }
    setAction(null);
    setShowSites(false);
  };

  const handleRepropagateFile = async (
    documentId?: string,
    siteIds?: string[],
    selectAll?: boolean,
    selectAllFiles?: boolean,
    fileIds?: string[]
  ) => {
    const siteIdsList = !selectAllSites ? siteIds : [];
    if (fromResource) {
      await repropagateFileResourceDocument(documentId, siteIdsList, selectAll, fileIds, selectAllFiles, filterResourceSites );
    } else {
      await repropagateFileDocument(documentId, siteIdsList, null, selectAll, selectAllFiles, fileIds, filterResourceSites );
    }
    refetchDocuments();
    setAction(null);
    setShowSites(false);
  };

  const openHistory = (document: Document) => {
    if (fromSite) {
      setEnableGetFileLogs(true);
      setEnableGetDocLogs(false);
    } else {
      setEnableGetFileLogs(true);
      setEnableGetDocLogs(true);
    }
    setHookDocumentId(document.id);
    return setShowDocumentInfoModal({ documentId: document.id, tab: 0 });
  };

  const openDocumentDetailModal = async (document: Document) => {
    setEnableGetFileLogs(false);
    setHookDocumentId(document.id);
    await handleDocumentTypeId(document.type.id);
    setShowDocumentDetail(true);
    setInitialCurrentIndex(document);
    !isWorkingSite &&
      siteId &&
      toast({
        id: "siteUpdateDocumentToast",
        title:
          <Text fontWeight="normal">
            <Trans
              i18nKey="siteActionAlert"
              ns="documents"
              values={{ siteName: siteHook.site?.name }}
              components={{ strong: <Text as="span" fontWeight="semibold" /> }}
            />
          </Text>,
        status: "warning",
        variant: "subtle",
        duration: 9000,
        isClosable: true,
      })
  };

  const handleDeleteFile = async (
    document: Document,
    fileId: string,
    siteIds: string[],
    selectAll: boolean,
  ) => {
    await deleteDocumentFile(document.id, fileId, siteIds, selectAll, fromResource ? filterResourceSites : filterSites);
    refetchDocuments();
    setShowSites(false);
  };

  const handleUpdateDoc = (updateOptions: UpdateDocumentOptions) => {
    const updatedDoc = {
      ...documents[currentIndex],
      ...updateOptions,
    };
    updateDocument(updatedDoc);
  };

  const handleUpdateFile = (
    id: string,
    name: string,
    expiresAt: Date,
    siteIds: string[],
    selectAll: boolean,
  ) => {
    updateFile(
      documents[currentIndex].id,
      id,
      [
        {
          id,
          name,
          expiresAt,
          updated: true,
        },
      ] as Partial<FileEntity>[],
      siteIds,
      selectAll,
      fromResource ? filterResourceSites : filterSites,
    );
  };

  const setInitialCurrentIndex = (document: Document) => {
    setCurrentIndex(documents.findIndex((d) => d?.id === document?.id));
  };

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + documents.length) % documents.length,
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % documents.length);
  };

  useEffect(() => {
    if (uploadData) setDroppedFile(processUploadedFiles(uploadData?.files));
  }, [uploadData]);

  useEffect(() => {
    if (currentIndex >= 0 && currentIndex) {
      setHookDocumentId(documents[currentIndex]?.id);
      if(documentTypeId !== documents[currentIndex]?.type.id && canDownloadFiles )
      setDocumentTypeId(documents[currentIndex]?.type.id)
    }
  }, [currentIndex, documents]);

  return (
    <>
      {location.pathname !== "/company/general-documents" ? (
        <Flex
          flexDirection={"column"}
          alignItems={"start"}
          width="100%"
          position="relative"
          overflow={"hidden"}
          marginTop={4}
          minHeight={"50vh"}
        >
            <InfiniteTable
              autosize={autosize}
              infiniteScroll={{
                dataLength: documents.length,
                hasNextPage: documentsHasNextPage,
                fetchNextPage: documentsFetchNextPage,
              }}
              isLoading={documentsLoading || action === "add"}
              showEmptyText={documents.length === 0}
              emptyText={t("noDocuments", { ns: "documents" })}
            >
          <Thead>
            <Tr>
              {tableColumns.map((column) => (
                <Th key={column.label}>
                  <TableColumnHeader
                    text={column.label}
                    filter={{
                      isActive:
                        !!filterDocuments[column.field] &&
                        (!Array.isArray(filterDocuments[column.field]) ||
                          !!filterDocuments[column.field][0]),
                      component: (
                        <ColumnFilterComponent
                          value={filterDocuments[column.field]}
                          type={column.filterType}
                          updateFilter={(value) =>
                            updateFilterDocuments(column.field, value)
                          }
                          tags={tags}
                          selectOptions={
                            column.field === "isPublic"
                              ? [
                                  {
                                    option: "1",
                                    label: t("yes", { ns: "common" }),
                                  },
                                  {
                                    option: "0",
                                    label: t("no", { ns: "common" }),
                                  },
                                ]
                              : [
                                  {
                                    option: "available",
                                    label: t(`combinedEvaluation.available`, {
                                      ns: "enum",
                                    }),
                                  },
                                  {
                                    option: "not_available",
                                    label: t(
                                      `combinedEvaluation.not_available`,
                                      {
                                        ns: "enum",
                                      },
                                    ),
                                  },
                                  {
                                    option: "not_applicable",
                                    label: t(
                                      `combinedEvaluation.not_applicable`,
                                      { ns: "enum" },
                                    ),
                                  },
                                  {
                                    option: "unevaluated",
                                    label: t(`combinedEvaluation.unevaluated`, {
                                      ns: "enum",
                                    }),
                                  },
                                ]
                          }
                        />
                      ),
                    }}
                    sort={
                      column.filterType !== "tags" && {
                        handler: (direction) =>
                          setSortDocuments({ field: column.field, direction }),
                        direction:
                          sortDocuments?.field === column.field
                            ? sortDocuments.direction
                            : null,
                      }
                    }
                  />
                </Th>
              ))}
              <Th width="180px" />
            </Tr>
          </Thead>
          <Tbody borderRadius="xl">
            {documents?.map((document) => {
              let showLastUploadedFile;
              if (
                document?.lastUploadedFileUri?.includes(".xlsx") ||
                document?.lastUploadedFileUri?.includes(".xls") ||
                document?.lastUploadedFileUri?.includes(".zip") ||
                document?.lastUploadedFileUri?.includes(".doc") ||
                document?.lastUploadedFileUri?.includes(".docx")
              ) {
                showLastUploadedFile = false;
              } else {
                showLastUploadedFile = true;
              }
              return (
                <Tr
                  key={document.id}
                  style={{
                    backgroundColor:
                      isDraggingOverRow === document?.id
                        ? COLORS.lightBlue
                        : document?.isManual
                          ? COLORS.lightYellow
                          : "white",
                  }}
                  onDragEnter={() => handleDragIn(document.id)}
                  onDrop={(e) => handleDrop(document, e)}
                  onDragOver={(e) => e.preventDefault()}
                >
                  <Td>
                    <Flex alignItems={"center"}>
                      <Tooltip
                        label={document.type.description}
                        placement="bottom-start"
                        openDelay={500}
                      >
                        <span>{document.type.name}</span>
                      </Tooltip>
                      {document.lastUploadedFileUri && showLastUploadedFile && (
                        <Tooltip
                          label={t("lastUpdatedFile")}
                          placement="bottom-start"
                        >
                          <span style={{ marginLeft: 10 }}>
                            <LuFileSearch2
                              color={COLORS.sikuroBlue}
                              cursor="pointer"
                              onClick={() =>
                                window.open(
                                  document.lastUploadedFileUri,
                                  "_blank",
                                )
                              }
                            />
                          </span>
                        </Tooltip>
                      )}
                    </Flex>
                  </Td>
                  {tableColumns.some((c) => c.field === "expiresAt") && (
                    <Td>
                      {!document.noExpiration && (
                        <Flex alignItems={"center"} gap={4}>
                          <ExpirationIcon days={document.expiresIn} />
                          {document.expiresAt
                            ? formatDateBasedOnLanguage(document.expiresAt)
                            : "--/--/----"}
                        </Flex>
                      )}
                      {document.noExpiration && (
                        <Flex alignItems={"center"} gap={4}>
                          <ExpirationIcon days={100} noExpiration={true} />
                          {t("noExpiration")}
                        </Flex>
                      )}
                    </Td>
                  )}
                  {tableColumns.some((c) => c.field === "isPublic") && (
                    <Td>
                      <Flex justifyContent="start">
                        {document.isPublic ? (
                          <HiOutlineLockOpen size={26} />
                        ) : (
                          <HiOutlineLockClosed size={26} />
                        )}
                      </Flex>
                    </Td>
                  )}
                  {tableColumns.some((c) => c.field === "tags") && (
                    <Td>
                      <Flex justifyContent="start">
                        <TagList tags={document.type.tags ?? []} />
                      </Flex>
                    </Td>
                  )}
                  {tableColumns.some((c) => c.field === "status") && (
                    <Td>
                      <Flex justifyContent="center">
                        <Flex
                          border="1px"
                          borderColor="gray.200"
                          width={"96px"}
                          height={"28px"}
                          borderRadius={"5px"}
                          fontSize={"10px"}
                          fontWeight={400}
                          alignContent={"center"}
                          justifyContent={"center"}
                          backgroundColor={
                            documentStatesMap[document.evaluationResult]
                          }
                        >
                          {t(
                            `combinedEvaluation.${document.evaluationResult}`,
                            {
                              ns: "enum",
                            },
                          )}
                        </Flex>
                      </Flex>
                    </Td>
                  )}
                  <Td width={"180px"}>
                    <Flex gap={4} justifyContent="center">
                      {communicationProps && (
                        <RenderIf permissions={permissions.chat}>
                          <CommentButton
                            onClick={(comment) => {
                              if (comment)
                                communicationProps.createResourceDocumentComment(
                                  `#[${document.type.name}](${document.id}) ${comment}`,
                                );
                            }}
                            comments={
                              communicationProps.resourceDocumentComments?.filter(
                                (c) => c.comment.includes(`(${document.id})`),
                              ) ?? []
                            }
                            isLoading={communicationProps.isFetching}
                            taggableUsers={communicationProps?.taggableUsers}
                          />
                        </RenderIf>
                      )}
                      {!isReadOnly && (
                        <RenderIf permissions={permissions.viewEvaluations}>
                          <HistoryButton
                            onClick={() => openHistory(document)}
                          />
                        </RenderIf>
                      )}
                      {!isReadOnly && (
                        <RenderIf permissions={permissions.addFile}>
                          <AddFileButton
                            justFile={false}
                            onClick={() => openDocumentDetailModal(document)}
                            filesCount={document.filesCount}
                          />
                        </RenderIf>
                      )}
                      {(isWorkingSite || hasCsePermission) && (
                        <SeeFileButton
                          onClick={() => openDocumentDetailModal(document)}
                          filesCount={document.filesCount}
                        />
                      )}
                      {!isReadOnly && (
                        <RenderIf permissions={permissions.delete}>
                          <DeleteButton
                            onClick={() =>
                              setShowConfirmDeleteModal(document.id)
                            }
                          />
                        </RenderIf>
                      )}
                    </Flex>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </InfiniteTable>
        </Flex>
      ) : (
        <GeneralDocumentsDnDView
          documents={documents}
          documentsLoading={generalDocumentsLoading}
          communicationProps={communicationProps}
          permissions={permissions}
          onReorder={onReorder}
          openDocumentDetailModal={openDocumentDetailModal}
          openHistory={openHistory}
          setShowConfirmDeleteModal={setShowConfirmDeleteModal}
          handleDragIn={handleDragIn}
          handleDrop={handleDrop}
        />
      )}

      {showConfirmDeleteModal && (
        <ConfirmAlert
          title={t("warning", { ns: "common" })}
          message={t("confirmDeleteDocument")}
          variant="warning"
          onCancel={() => setShowConfirmDeleteModal(false)}
          isLoading={deleteDocumentIsLoading}
          onConfirm={async () => {
            await deleteDocument(showConfirmDeleteModal);
            setShowConfirmDeleteModal(false);
          }}
        />
      )}

      {showDocumentDetail && (
        <DocumentDetailModal
          deleteIsLoading={deleteIsLoading}
          document={documents[currentIndex]}
          fileIsLoading={documentFilesFetching}
          isOpen={showDocumentDetail}
          onClose={() => {
            setShowDocumentDetail(false);
            setCurrentIndex(null);
            setHookDocumentId(undefined);
            toast.close("siteUpdateDocumentToast");
          }}
          errorMsg={errorMsg}
          setErrorMsg={setErrorMsg}
          successMsg={successMessage || successMsg}
          setSuccessMsg={(value) => {
           setSuccessMessage(value)
           setSuccessMsg(value)
          }}
          isReadOnly={isReadOnly}
          files={documentFiles}
          addFile={(file, siteIds, selectAllSites) =>
            handleAddFile(
              documents[currentIndex].id,
              siteIds,
              file,
              selectAllSites,
            )
          }
          addFileIsLoading={isAddingFile}
          deleteFile={(fileId, siteIds, selectAllSites) =>
            handleDeleteFile(
              documents[currentIndex],
              fileId,
              siteIds,
              selectAllSites,
            )
          }
          editDocument={(
            expiresAt,
            isPublic,
            noExpiration,
            siteIds,
            fileEntity,
            selectAllSites,
          ) =>
            handleUpdateDoc({
              expiresAt,
              isPublic,
              noExpiration,
              siteIds,
              selectAll: selectAllSites,
            })
          }
          editFile={(id, name, expiresAt, siteIds, selectAllSites) =>
            handleUpdateFile(id, name, expiresAt, siteIds, selectAllSites)
          }
          evaluateDocument={(
            documentId,
            evaluationState,
            expirationDate,
            noEvaluationExpiration,
            siteIds,
            selectAllSites,
          ) => {
            if (expirationDate) {
              const date = parse(expirationDate, getDateFormat(), new Date());
              evaluateDocument({
                documentId,
                evaluationState,
                expirationDate: date,
                noEvaluationExpiration,
                siteIds,
                selectAll: selectAllSites,
                filters: fromResource ? filterResourceSites : filterSites ?? {}
              });
            } else
              evaluateDocument({
                documentId,
                evaluationState,
                expirationDate: undefined,
                noEvaluationExpiration,
                siteIds,
                selectAll: selectAllSites,
                filters: fromResource ? filterResourceSites : filterSites ?? {}
              });
          }}
          repropagateEvaluationDocument={(
            documentId,
            siteIds,
            selectAll
          ) => {
            repropagateEvaluationDocument({documentId, siteIds, selectAll})
          }}
          repropagateFileDocument={(documentId, siteIds, selectAllSites, selectAllFiles, fileIds) =>
            handleRepropagateFile(
              documentId,
              siteIds,
              selectAllSites,
              selectAllFiles,
              fileIds
            )
          }
          sites={fromResource ? resourceSites : documentSites}
          onPrev={handlePrev}
          onNext={handleNext}
          siteCount={siteCount}
          renameModels={renameModel?.download}
          communicationProps={communicationProps}
          type="document"
          downloader={downloader}
          siteGeneralDocument={siteGeneralDocument}
          hasSites={fromResource ? resourceHasSites : hasSites}
          isFetchingSites={
            fromResource ? isFetchingResourceSites : isFetchingSites
          }
          filterSites={fromResource ? filterResourceSites : filterSites}
          updateFilterSites={
            fromResource ? updateFilterResourceSites : updateFilterSites
          }
          setSortSites={fromResource ? setSortResourceSites : setSortSites}
          hasSitesNextPage={hasSitesNextPage}
          fetchSitesNextPage={fetchSitesNextPage}
          sortSites={fromResource ? sortResourceSites : sortSites}
          documentsFetching={documentsFetching}
          docDetailPermissions={permissions}
          setFilterSites={fromResource ? setFilterResourceSites : setFilterSites}
          showRepropagate={showRepropagate}
        />
      )}

      {showDocumentInfoModal && (
        <DocumentHistoryModal
          documentLogsFetching={documentLogsFetching}
          documentFileLogsFetching={documentFileLogsFetching}
          onClose={() => {
            setHookDocumentId(undefined);
            setShowDocumentInfoModal(false);
          }}
          documentFileLogs={documentFileLogs}
          documentActionLogs={documentActionLogs}
          filterDocumentLog={filterDocumentLog}
          updateFilterDocumentLog={updateFilterDocumentLog}
          sortDocumentLog={sortDocumentLog}
          setSortDocumentLog={setSortDocumentLog}
          fileLogsHasNextPage={fileLogsHasNextPage}
          fileLogsFetchNextPage={fileLogsFetchNextPage}
          docLogsHasNextPage={docLogsHasNextPage}
          docLogsFetchNextPage={docLogsFetchNextPage}
          fromSite={fromSite}
        />
      )}

      {showConfirmUploadeModal && (
        <ConfirmAlert
          title={t("warning", { ns: "common" })}
          message={t("dropDocumentMessage", {
            documentName: uploadData.documentName,
          })}
          variant="warning"
          onCancel={() => {
            setShowConfirmUploadModal(false), setAction(null);
          }}
          onConfirm={() => {
            setAction("add");
            handleAddFile(uploadData.id);
            setShowConfirmUploadModal(false);
          }}
        />
      )}

      {showSites && (
        <BaseModal
          onClose={() => setShowSites(false)}
          includeFooterButton={false}
        >
          <SelectSitesView
            title={t("selectSites")}
            reminderText={t("noSiteSelected", { ns: "common" })}
            alertText={t("siteSelectionAlert", { ns: "common" })}
            onClose={() => {
              (fromResource ? setFilterResourceSites(null) : setFilterSites(null))
              setShowSites(false)
            }}
            onSaveAction={(siteIds, selectAllSites) => {
              handleAddFile(documentId, siteIds, droppedFile, selectAllSites),
                fromResource ? setFilterResourceSites(null) : setFilterSites(null)
            }
            }
            siteList={fromResource ? resourceSites : documentSites}
            includeFooterButton={true}
            operationIsBusy={false}
            filterResourceSites={fromResource ? filterResourceSites : filterSites}
            updateFilterResourceSites={fromResource ? updateFilterResourceSites : updateFilterSites}
            setSortResourceSites={setSortSites}
            sortResourceSites={sortSites}
            setSelectAllSites={setSelectAllSites}
            selectAllSites={selectAllSites}
            hasNextPage={hasSitesNextPage}
            fetchNextPage={fetchSitesNextPage}
            siteCount={siteCount}
            showSelectAll={true}
          />
        </BaseModal>
      )}
      {errorMsg && !showDocumentDetail && (
        <Alert
          title={t("warning", { ns: "common" })}
          message={t(errorMsg, { ns: "errors" })}
          variant="info"
          onClose={() => {
            setErrorMsg(null);
            setShowSites(false);
          }}
        />
      )}
       {success && (
        <Alert
          title={t("warning", { ns: "common" })}
          message={t(success, { ns: "common" })}
          variant="info"
          onClose={() => {
            setSuccess(null);
            setShowSites(false);
          }}
        />
      )}
    </>
  );
}

export default DocumentsTable;
