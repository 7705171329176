import ContentLayout from "../../../layout/ContentLayout";
import { Evaluable } from "../../../../domain/entities/evaluable.enum";
import useSiteApproveEvaluationsViewModel, {
  PendingEvaluationsFilters,
} from "../../../hooks/Site/useSiteApproveEvaluationsViewModel";
import { useParams } from "react-router-dom";
import {
  Button,
  Checkbox,
  Flex,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import { EvaluationButton } from "../../Common/ResourceEvaluationToggle";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import PendingEvaluation from "../../../../domain/entities/pending-evaluation";
import { COLORS } from "../../../assets/theme/colors";
import { ConfirmAlert } from "../../../screens/Common/ConfirmAlert";
import InfiniteTable from "../../Common/table/InfiniteTable";
import TableColumnHeader from "../../Common/table/TableColumnHeader";
import ColumnFilterComponent from "../../Common/table/ColumnFilterComponent";
import { ResourceDocumentEvaluationState } from "../../../../domain/entities/resourceDocumentEvaluationState.enum";
import RenderIf, { useHasPermissions } from "../../Permissions/RenderIf";
import { Permission } from "../../Permissions/Permissions";
import SearchInput from "../../../screens/Common/SearchInput";
import { LuFileSearch2 } from "react-icons/lu";
import AddFileButton from "../../Common/table/AddFileButton";
import ActionButton from "../../Common/table/ActionButton";
import ActionBar from "../../Common/ActionBar";
import ActionBarItem from "../../Common/ActionBarItem";
import { DocumentDetailModal } from "../common/DocumentDetailModal";
import { useSupplierOrSiteFilesDownloader } from "../../../hooks/Document/useDocumentsFilesDownloader";
import { useApp } from "../../../../app";
import { Alert } from "../../../screens/Common/Alert";

type Props = {
  type: Evaluable;
};
const SiteApproveEvaluationsResourceView = ({ type }: Props) => {
  const { siteId } = useParams();
  const {
    evaluations,
    evaluationsIsLoading,
    pendingEvaluationsHasNextPage,
    pendingEvaluationsFetchNextPage,
    filterEvaluations,
    setFilterEvaluations,
    updateFilterEvaluations,
    sort,
    setSort,
    approve,
    approveIsLoading,
    setSearch,
    setEvaluationId,
    setSelectAllEvaluation,
    evaluationFiles,
    evaluationFilesIsLoading,
    setDocumentId,
    filesCount,
    setDocTypeId,
    renameModels,
    evaluationIds,
    setEvaluationIds,
    countPendingEvaluations,
    noRejectEvaluation,
    setNoRefejectEvaluation,
    setEnableGetFiles,
    docTypeId
  } = useSiteApproveEvaluationsViewModel(siteId, type);
  const { t } = useTranslation("sites");

  const [showConfirmAlert, setShowConfirmAlert] = useState<boolean>(false);
  const [selectedEvaluation, setSelectedEvaluation] = useState<{
    item: PendingEvaluation;
    value: boolean;
  }>(undefined);
  const [showDocumentDetail, setShowDocumentDetail] = useState(false);
  const [selectAll, setSelectAll] = useState(false)
  const [selectAllPaginatedEvaluations, setSelectAllPaginatedEvaluations] =
    useState<boolean>(false);
  const [checkButton, setCheckButton] = useState<"approved" | "rejected">();
  const [currentIndex, setCurrentIndex] = useState<number>();

  const downloader = useSupplierOrSiteFilesDownloader(siteId, "site");

  const { context } = useApp();

  const validateEvaluation = async (
    evaluationIds: string[],
    isApproved: boolean,
  ) => {
    await approve({
      resource: type,
      evaluationIds: evaluationIds,
      isApproved,
    });
    setFilterEvaluations({})
    setEvaluationIds([])
  };

  const fieldEvaluations = filterEvaluations ?? {
    company: "",
    requirementId: "",
    result: "",
  };

  useEffect(() => {
    if (selectAll && evaluations) {
      setEvaluationIds(evaluations.map(evaluation => evaluation.id));
    }
  }, [selectAll, evaluations]);

  const columns: {
    field: keyof PendingEvaluationsFilters;
    type: "text" | "select" | "date-range";
    options?: Record<string, string>;
  }[] = [{ field: "company", type: "text" }];

  if (type !== Evaluable.COMPANY) {
    if (type !== Evaluable.WORKER) {
      columns.push({ field: "resource", type: "text" });
    } else {
      columns.push({ field: "lastName", type: "text" });
      columns.push({ field: "firstName", type: "text" });
    }
  }

  columns.push(
    { field: "requirement", type: "text" },
    {
      field: "result",
      type: "select",
      options: ResourceDocumentEvaluationState,
    },
  );

  useEffect(() => {
    setFilterEvaluations({});
  }, [type]);

  const openDocumentDetailModal = async (evaluation: PendingEvaluation) => {
    setEnableGetFiles(true);
    setDocumentId(evaluation.document.id);
    setDocTypeId(evaluation.document.type.id)
    setShowDocumentDetail(true);
    setInitialCurrentIndex(evaluation);
  };

  const toggleSelectAll = (value: boolean) => {
    setSelectAll(value);
    if (!value) {
      setEvaluationIds([]);
      setSelectAllPaginatedEvaluations(false);
    } else {
      setSelectAllEvaluation(value);
      setEvaluationIds(evaluations.map((evaluation) => evaluation.id));
    }
    if (pendingEvaluationsHasNextPage) {
      setSelectAllPaginatedEvaluations(true);
    }
  };

  const handleSelectionButton = () => {
    if (selectAllPaginatedEvaluations) {
      setEvaluationIds([]);
      setSelectAll(false);
      setSelectAllEvaluation(false);
      setSelectAllPaginatedEvaluations(false);
    } else {
      setSelectAllPaginatedEvaluations(true);
      setSelectAllEvaluation(true);
    }
  };

  const toggleItem = (id: string) => {
    let updatedEvaluationsIds;

    if (evaluationIds && !evaluationIds.includes(id)) {
      updatedEvaluationsIds = [...evaluationIds, id];
    } else {
      updatedEvaluationsIds = evaluationIds.filter((i) => i !== id);
    }

    setEvaluationIds(updatedEvaluationsIds);

    if (updatedEvaluationsIds.length === evaluationIds.length) {
      setSelectAll(true);
      setSelectAllEvaluation(true);
    } else {
      setSelectAll(false);
      setSelectAllEvaluation(false);
    }
  };

  const setInitialCurrentIndex = (evaluation: PendingEvaluation) => {
    setCurrentIndex(evaluations.findIndex((d) => d?.id === evaluation?.id));
  };

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + evaluations.length) % evaluations.length
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % evaluations.length);
  };

  useEffect(() => {
    if (currentIndex != null) {
      setDocumentId(evaluations[currentIndex]?.document.id);
      if(docTypeId !== evaluations[currentIndex]?.document?.type.id)
      setDocTypeId(evaluations[currentIndex]?.document?.type.id)
    }
  }, [currentIndex, evaluations]);

  return (
    <ContentLayout
      action={
        <ActionBar>
          <ActionBarItem
            onClick={() => {
              setShowConfirmAlert(true);
              setCheckButton("approved");
            }}
            icon={AiFillCheckCircle}
            description={t("approveAllEvaluationTooltip", { ns: "sites" })}
            isDisabled={!selectAll && evaluationIds.length === 0}
            color={"white"}
            bgColor={
              !selectAll && evaluationIds.length === 0
                ? "#8bc7e5"
                : COLORS.sikuroBlue
            }
            disabledDescription={t("disabledAllEvaluationTooltip", {
              ns: "sites",
            })}
          />
        {context.site.options.isEvaluationApprovalRequiredForGlobalEvaluation &&   <ActionBarItem
            onClick={() => {
              setShowConfirmAlert(true);
              setCheckButton("rejected");
            }}
            icon={AiFillCloseCircle}
            description={t("rejectAllEvaluationTooltip", { ns: "sites" })}
            isDisabled={!selectAll && evaluationIds.length === 0}
            color={"white"}
            bgColor={
              !selectAll && evaluationIds.length === 0
                ? "#f9b1b1"
                : COLORS.error
            }
            disabledDescription={t("disabledAllEvaluationTooltip", {
              ns: "sites",
            })}
          />}
        </ActionBar>
      }
    >
      <Flex
        justifyContent={"space-between"}
        alignItems="center"
        w={"100%"}
        marginTop={10}
        width={"calc(100vw - 270px)"}
        marginLeft={10}
      >
        <Text>{t("subtitle", { ns: "sites" })}</Text>
        <SearchInput onSearch={setSearch} />
      </Flex>
      <Flex
        flexDirection={"column"}
        alignItems={"start"}
        border="1px solid"
        borderColor="gray.300"
        borderRadius="10px"
        width={"calc(100vw - 270px)"}
        marginLeft={10}
        marginRight={10}
        position="relative"
        overflow={"hidden"}
        mt={4}
      >
        <InfiniteTable
          autosize
          infiniteScroll={{
            dataLength: evaluations.length,
            hasNextPage: pendingEvaluationsHasNextPage,
            fetchNextPage: pendingEvaluationsFetchNextPage,
          }}
          isLoading={evaluationsIsLoading}
          tableId="site-approved-evaluations"
          showEmptyText={evaluations?.length === 0}
          emptyText={t("noEvaluations", { ns: "sites" })}
          isCheckboxTable={true}
        >
          <Thead width={"100%"}>
            <Tr>
              <Th key={"selectAllCheckbox"} width={10}>
                <Checkbox
                  borderColor={"gray.500"}
                  isChecked={selectAll}
                  onChange={() => toggleSelectAll(!selectAll)}
                ></Checkbox>
              </Th>
              {columns.map((c) => (
                <Th key={c.field}>
                  <TableColumnHeader
                    text={t(`${c.field}`, { ns: "sites" })}
                    filter={
                      c.field != "result" && {
                        isActive:
                          !!fieldEvaluations[c.field] &&
                          (!Array.isArray(fieldEvaluations[c.field]) ||
                            !!fieldEvaluations[c.field][0]),
                        component: (
                          <ColumnFilterComponent
                            value={fieldEvaluations[c.field]}
                            type={c.type}
                            updateFilter={(value) =>
                              updateFilterEvaluations(c.field, value)
                            }
                            namespace="enum"
                            selectOptions={c.options}
                          />
                        ),
                      }
                    }
                    sort={
                      c.field != "result" && {
                        handler: (direction) =>
                          setSort({ field: c.field, direction }),
                        direction:
                          sort?.field === c.field ? sort.direction : null,
                      }
                    }
                  />
                </Th>
              ))}
              <Th width={context.site.options.isEvaluationApprovalRequiredForGlobalEvaluation ? "180px" : '150px'} />
            </Tr>
          </Thead>
          <Tbody borderRadius="xl">
            <Tr width={"100%"}>
              {selectAll && (
                <Th colSpan={columns.length + 2} backgroundColor={"gray.100"}>
                  <Text textAlign="center" mx="auto">
                    {!selectAllPaginatedEvaluations &&
                      t("evaluationsSelectedVisible")}
                    {selectAllPaginatedEvaluations &&
                      t("evaluationsSelectedNotVisible", {
                        count: countPendingEvaluations
                      })}
                    {pendingEvaluationsHasNextPage && (
                      <Button
                        mt="10px"
                        ml="4px"
                        colorScheme="blue"
                        variant="link"
                        onClick={() => handleSelectionButton()}
                      >
                        {t(
                          selectAll ? "clearSelection" : "evaluationsSelectAll",
                        )}
                      </Button>
                    )}
                  </Text>
                </Th>
              )}
            </Tr>
            {evaluations &&
              evaluations.map((item) => {
                let showLastUploadedFile;
                if (
                  item?.document.lastUploadedFileUri?.includes(".xlsx") ||
                  item?.document.lastUploadedFileUri?.includes(".xls") ||
                  item?.document.lastUploadedFileUri?.includes(".zip") ||
                  item?.document.lastUploadedFileUri?.includes(".doc") ||
                  item?.document.lastUploadedFileUri?.includes(".docx")
                ) {
                  showLastUploadedFile = false;
                } else {
                  showLastUploadedFile = true;
                }
                return (
                  <Tr key={item.id} width={"100%"}>
                    <Td width={"10px"}>
                      <Checkbox
                        isChecked={
                          evaluationIds && evaluationIds.length > 0
                            ? evaluationIds?.includes(item?.id)
                            : false
                        }
                        onChange={() => toggleItem(item?.id)}
                      ></Checkbox>
                    </Td>
                    <Td>{item.company.name}</Td>
                    {type !== Evaluable.COMPANY &&
                      type !== Evaluable.WORKER && (
                        <Td>{item.resource.name}</Td>
                      )}
                    {type === Evaluable.WORKER && (
                      <Td>{item.resource.lastName}</Td>
                    )}
                    {type === Evaluable.WORKER && (
                      <Td>{item.resource.firstName}</Td>
                    )}
                    <Tooltip
                      label={item.evaluation.resource.type.description}
                      placement="bottom-start"
                      openDelay={500}
                    >
                      <Td>
                        {item.evaluation.resource.type.name}{" "}
                        {!item.isOptional ? (
                          <span style={{ color: COLORS.error }}>*</span>
                        ) : (
                          ""
                        )}
                      </Td>
                    </Tooltip>
                    <Td justifyContent="center">
                      <EvaluationButton
                        value={item.evaluation.result}
                        evaluationType="document"
                      />
                    </Td>
                    <RenderIf
                      permissions={Permission.Sites_ConfirmPendingEvals}
                      fallback={
                        <Td
                          sx={{ justifyContent: "center", gap: "10px" }}
                          width="150px"
                        />
                      }
                    >
                      <Td
                        width={context.site.options.isEvaluationApprovalRequiredForGlobalEvaluation ? "180px" : '150px'}
                        sx={{ justifyContent: "left", gap: "10px" }}
                      >
                        <Tooltip
                          label={t("approveEvaluationTooltip", {
                            ns: "sites",
                          })}
                        >
                          <span>
                            <ActionButton
                              aria-label="approve"
                              onClick={() => {
                                setCheckButton('approved')
                                setSelectedEvaluation({ item, value: true });
                                setShowConfirmAlert(true);
                              }}
                              icon={
                                <AiFillCheckCircle
                                  color={COLORS.sikuroBlue}
                                  size="30px"
                                />
                              }
                            />
                          </span>
                        </Tooltip>
                       {context.site.options.isEvaluationApprovalRequiredForGlobalEvaluation && <Tooltip
                          label={t("rejectEvaluationTooltip", {
                            ns: "sites",
                          })}
                        >
                          <span>
                            <ActionButton
                              aria-label="abort"
                              onClick={() => {
                                setCheckButton('rejected')
                                setSelectedEvaluation({ item, value: false });
                                setShowConfirmAlert(true);
                              }}
                              icon={
                                <AiFillCloseCircle
                                  color={COLORS.error}
                                  size="30px"
                                />
                              }
                            />
                          </span>
                        </Tooltip>}
                        <AddFileButton
                          justFile={false}
                          onClick={() => openDocumentDetailModal(item)}
                          filesCount={item.document.filesCount}
                          tooltipLabal={t("viewDocument", { ns: "documents" })}

                        />
                        <RenderIf
                          permissions={Permission.Sites_ReviewEvalDownloadFile}>
                          {item.document.lastUploadedFileUri &&
                            showLastUploadedFile && (
                              <Tooltip
                                label={t("lastUpdatedFile", { ns: "documents" })}
                                placement="bottom-start"
                              >
                                <span style={{ marginLeft: 10 }}>
                                  <ActionButton
                                    mt={1}
                                    aria-label="view-last-file"
                                    onClick={() => {
                                      window.open(
                                        item.document.lastUploadedFileUri,
                                        "_blank",
                                      );
                                    }}
                                    icon={
                                      <LuFileSearch2
                                        color={COLORS.sikuroBlue}
                                        cursor="pointer"
                                        fontSize="22px"
                                      />
                                    }
                                  />
                                </span>
                              </Tooltip>
                            )}
                        </RenderIf>
                      </Td>
                    </RenderIf>
                  </Tr>
                );
              })}
          </Tbody>
        </InfiniteTable>
        {showConfirmAlert && (
          <ConfirmAlert
            title={t("attention", { ns: "common" })}
            message={
              checkButton === "approved"
                ? t("confirmAllEvaluation")
                : checkButton === "rejected"
                  ? t("rejectAllEvaluation")
                  : selectedEvaluation.value
                    ? t("confirmEvaluation")
                    : t("rejectEvaluation")
            }
            variant="warning"
            closeClickingOutside={false}
            onCancel={() => {
              setSelectedEvaluation(undefined);
              setShowConfirmAlert(false);
            }}
            onConfirm={async () => {
              await validateEvaluation(
                evaluationIds.length > 0 ? evaluationIds : [selectedEvaluation.item.id],
                checkButton === "approved" ? true : false,
              );
              setSelectedEvaluation(undefined);
              setShowConfirmAlert(false);
              setSelectAll(false)
            }}
            isLoading={approveIsLoading}
            isCloseDisabled={approveIsLoading}
          />
        )}

        {showDocumentDetail && (
          <DocumentDetailModal
            document={evaluations[currentIndex]}
            fileIsLoading={evaluationFilesIsLoading}
            filesCount={filesCount}
            isOpen={showDocumentDetail}
            onClose={() => {
              setShowDocumentDetail(false);
              setCurrentIndex(null);
              setDocumentId(undefined);
            }}
            renameModels={renameModels?.download}
            downloader={downloader}
            isReadOnly={true}
            files={evaluationFiles}
            onPrev={handlePrev}
            onNext={handleNext}
            type="evaluation"
            docDetailPermissions={{
              addFile: Permission.Sites_ReviewEvalAddFile,
              chat: Permission.Sites_ReviewEvalChat,
              delete: Permission.Sites_ReviewEvalDeleteFile,
              downloadAll: Permission.Sites_ReviewEvalDownloadAll,
              edit: Permission.Sites_ReviewEvalEditFile,
              evaluate: Permission.Sites_ReviewEvalEvaluate,
              setExpiration: Permission.Sites_ReviewEvalSetExpiration,
              viewEvaluations: Permission.Sites_Manage,
              addNewFile: Permission.Sites_ReviewEvalAddFile,
              report: Permission.Sites_ReviewEvalReport,
            }}
            validateEvaluation={async (evaluationIds: string[], isApproved: boolean) => {
              await validateEvaluation(
                evaluationIds.length > 0 ? evaluationIds : [selectedEvaluation.item.id],
                isApproved
              );
            }}
            approveIsLoading={approveIsLoading}
          />
        )}
        {noRejectEvaluation &&  <Alert
          title={t("warning", { ns: "common" })}
          message={t('noRejectEvaluation', { ns: "errors" })}
          variant="info"
          onClose={() => {
            setNoRefejectEvaluation(undefined);
          }}
        />}
      </Flex>
    </ContentLayout>
  );
};

export default SiteApproveEvaluationsResourceView;
