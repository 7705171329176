import { makeDocumentsRepository } from './repositories';
import UpdateFile from '../../../../domain/interactors/documents/updateFile';
import GetDocument from '../../../../domain/interactors/documents/getDocument';
import GetDocumentLog from '../../../../domain/interactors/documents/getDocumentLog';
import CreateDocument from '../../../../domain/interactors/documents/createDocument';
import DeleteDocument from '../../../../domain/interactors/documents/deleteDocument';
import UpdateDocument from '../../../../domain/interactors/documents/updateDocument';
import GetDocumentFiles from '../../../../domain/interactors/documents/getDocumentFiles';
import GetDocumentSites from '../../../../domain/interactors/documents/getDocumentSites';
import GetTaggableUsers from '../../../../domain/interactors/documents/getTaggableUsers';
import AddFileToDocument from '../../../../domain/interactors/documents/addFileToDocument';
import EvaluateSiteDocument from '../../../../domain/interactors/documents/evaluateDocument';
import GetDocumentFilesLog from '../../../../domain/interactors/documents/getDocumentFilesLog';
import GetTaggableDocuments from '../../../../domain/interactors/documents/getTaggableDocuments';
import GetTimeTableDocuments from '../../../../domain/interactors/documents/getTimeTableDocuments';
import GetDocumentEvaluations from '../../../../domain/interactors/documents/getDocumentEvaluations';
import DeleteFileFromDocument from '../../../../domain/interactors/documents/deleteFileFromDocument';
import DeleteResourceDocument from '../../../../domain/interactors/documents/deleteResourceDocument';
import GetNotificationsStatus from '../../../../domain/interactors/documents/getNotificationsStatus';
import EvaluateGeneralDocument from '../../../../domain/interactors/documents/evaluateGeneralDocument';
import GetResourceDocumentNote from '../../../../domain/interactors/documents/getResourceDocumentNote';
import GetSiteSupplierDocuments from '../../../../domain/interactors/documents/getSiteSupplierDocuments';
import CreatePropagableDocument from '../../../../domain/interactors/documents/createPropagableDocuments';
import AddFileToResourceDocument from '../../../../domain/interactors/documents/addFileToResourceDocument';
import UpdateNotificationsStatus from '../../../../domain/interactors/documents/updateNotificationsStatus';
import CreateResourceDocumentNote from '../../../../domain/interactors/documents/createResourceDocumentNote';
import GetResourceDocumentComments from '../../../../domain/interactors/documents/getResourceDocumentComments';
import GetSiteRequirementsResource from '../../../../domain/interactors/documents/getSiteRequirementsResource';
import EvaluateSiteSupplierDocument from '../../../../domain/interactors/documents/evaluateSiteSupplierDocument';
import CreateResourceDocumentComment from '../../../../domain/interactors/documents/createResourceDocumentComment';
import GetDocumentCommentGroup from '../../../../domain/interactors/documents/getDocumentCommentGroup';
import ReorderDocuments from '../../../../domain/interactors/documents/reorderDocuments';
import CreateAiTask from '../../../../domain/interactors/documents/ai/createAiTask';
import GetAiTasks from '../../../../domain/interactors/documents/ai/getAiTasks';
import GetAiTaskDetail from '../../../../domain/interactors/documents/ai/getAiTaskDetail';
import DeleteAiTask from '../../../../domain/interactors/documents/ai/deleteAiTask';
import ConfirmAiTask from '../../../../domain/interactors/documents/ai/confirmAiTask';
import GetAiTaskSites from '../../../../domain/interactors/documents/ai/getAiTaskSites';
import RepropagateEvaluationGeneralDocument from '../../../../domain/interactors/documents/repropagateEvaluationGeneralDocument';
import RepropagateFileToDocument from '../../../../domain/interactors/documents/repropagateFileToDocument';
import RepropagateFileResourceDocument from '../../../../domain/interactors/documents/repropagateFileResourceDocument';
import GetSiteSupplierDocumentsRejected from '../../../../domain/interactors/documents/getSiteSupplierDocumentsRejected';

export const makeCreateDocument = () => {
	return new CreateDocument(makeDocumentsRepository());
};

export const makeCreatePropagableDocuments = () => {
	return new CreatePropagableDocument(makeDocumentsRepository());
};

export const makeUpdateDocument = () => {
	return new UpdateDocument(makeDocumentsRepository());
};

export const makeGetDocument = () => {
	return new GetDocument(makeDocumentsRepository());
};

export const makeDeleteDocument = () => {
	return new DeleteDocument(makeDocumentsRepository());
};

export const makeDeleteResourceDocument = () => {
	return new DeleteResourceDocument(makeDocumentsRepository());
};

// returns the document of a supplier on a specific site
export const makeGetSiteSupplierDocuments = () => {
	return new GetSiteSupplierDocuments(makeDocumentsRepository());
};

export const makeGetSiteSupplierDocumentsRejected = () => {
	return new GetSiteSupplierDocumentsRejected(makeDocumentsRepository());
};

// File useCases

export const makeGetDocumentFiles = () => {
	return new GetDocumentFiles(makeDocumentsRepository());
};

export const makeAddFileToDocument = () => {
	return new AddFileToDocument(makeDocumentsRepository());
};

export const makeRepropagateFileToDocument = () => {
	return new RepropagateFileToDocument(makeDocumentsRepository());
}

export const makeAddFileToResourceDocument = () => {
	return new AddFileToResourceDocument(makeDocumentsRepository());
};

export const makeRepropagateFileResourceDocument = () => {
	return new RepropagateFileResourceDocument(makeDocumentsRepository());
}

export const makeDeleteFileFromDocument = () => {
	return new DeleteFileFromDocument(makeDocumentsRepository());
};

export const makeGetDocumentLog = () => {
	return new GetDocumentLog(makeDocumentsRepository());
};

export const makeGetDocumentFilesLog = () => {
	return new GetDocumentFilesLog(makeDocumentsRepository());
};

export const makeEvaluateSiteDocument = () => {
	return new EvaluateSiteDocument(makeDocumentsRepository());
};

export const makeEvaluateGeneralDocument = () => {
	return new EvaluateGeneralDocument(makeDocumentsRepository());
};

export const makeRepropagateEvaluationGeneralDocument = () => {
	return new RepropagateEvaluationGeneralDocument(makeDocumentsRepository());
};

export const makeEvaluateSiteSupplierDocument = () => {
	return new EvaluateSiteSupplierDocument(makeDocumentsRepository());
};

export const makeGetDocumentEvaluations = () => {
	return new GetDocumentEvaluations(makeDocumentsRepository());
};

export const makeGetTimeTableDocuments = () => {
	return new GetTimeTableDocuments(makeDocumentsRepository());
};

export const makeUpdateFile = () => {
	return new UpdateFile(makeDocumentsRepository());
};

export const makeGetResourceDocumentNote = () => {
	return new GetResourceDocumentNote(makeDocumentsRepository());
};

export const makeCreateResourceDocumentNote = () => {
	return new CreateResourceDocumentNote(makeDocumentsRepository());
};

export const makeGetResourceDocumentComments = () => {
	return new GetResourceDocumentComments(makeDocumentsRepository());
};

export const makeGetDocumentCommentGroup = () => {
	return new GetDocumentCommentGroup(makeDocumentsRepository());
};

export const makeCreateResourceDocumentComment = () => {
	return new CreateResourceDocumentComment(makeDocumentsRepository());
};

export const makeGetTaggableUsers = () => {
	return new GetTaggableUsers(makeDocumentsRepository());
};

export const makeGetTaggableDocuments = () => {
	return new GetTaggableDocuments(makeDocumentsRepository());
};

export const makeGetSiteRequirementsResource = () => {
	return new GetSiteRequirementsResource(makeDocumentsRepository());
};

export const makeGetNotificationsStatus = () => {
	return new GetNotificationsStatus(makeDocumentsRepository());
};
export const makeUpdatetNotificationsStatus = () => {
	return new UpdateNotificationsStatus(makeDocumentsRepository());
};
export const makeDocumentSites = () => {
	return new GetDocumentSites(makeDocumentsRepository());
};
export const makeReorderDocuments = () => {
	return new ReorderDocuments(makeDocumentsRepository());
};
// AI section
export const makeCreateAiTask = () => {
	return new CreateAiTask(makeDocumentsRepository());
};

export const makeGetAiTasks = () => {
	return new GetAiTasks(makeDocumentsRepository());
};

export const makeGetAiTaskDetail = () => {
	return new GetAiTaskDetail(makeDocumentsRepository());
};

export const makeDeleteAiTask = () => {
	return new DeleteAiTask(makeDocumentsRepository());
};

export const makeConfirmAiTask = () => {
	return new ConfirmAiTask(makeDocumentsRepository());
};

export const makeGetAiTaskSites = () => {
	return new GetAiTaskSites(makeDocumentsRepository());
};
