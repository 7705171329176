import {
  Box,
  Button,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { LuPackagePlus } from "react-icons/lu";
import { FaRegSave } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import React, { FC, useEffect, useState } from "react";
import LoadingView from "../../Common/LoadingView";
import { HiOutlineChatAlt2 } from "react-icons/hi";
import { COLORS } from "../../../assets/theme/colors";
import DataBox from "../../../components/Common/DataBox";
import Vehicle from "../../../../domain/entities/vehicle";
import ContentLayout from "../../../layout/ContentLayout";
import { useNavigate, useParams } from "react-router-dom";
import { MdCancel, MdClose, MdEdit } from "react-icons/md";
import ActionBar from "../../../components/Common/ActionBar";
import ResourceDocumentsView, {
  ResourceDocumentProps,
} from "../ResourceDocumentsView";
import RenderIf from "../../../components/Permissions/RenderIf";
import ActionBarItem from "../../../components/Common/ActionBarItem";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Permission } from "../../../components/Permissions/Permissions";
import FormTextField from "../../../components/Common/forms/FormTextField";
import useVehicleViewModel from "../../../hooks/Resources/Vehicles/useVehicleViewModel";
import { DocumentTypeCategory } from "../../../../domain/entities/documentTypeCategory.enum";
import { useDocumentFilesDownloader } from "../../../hooks/Document/useDocumentsFilesDownloader";
import DocumentCommunicationModal from "../../../components/Views/common/DocumentCommunicationModal";
import useVehicleDocumentsViewModel from "../../../hooks/Resources/Vehicles/useVehicleDocumentsViewModel";
import BaseModal from "../../../components/Common/alerts/BaseModal";
import SelectSitesView from "../../../components/Views/common/SelectSitesView";
import DOMPurify from "dompurify";
import SelectBadgesTable from "../../../components/Views/Badge/SelectBadgesTable";
import { BiLink } from "react-icons/bi";
import { Alert } from "../../Common/Alert";
import { DeleteActionAlert } from "../../Common/DeleteActionAlert";
import MachineVehicleMigrationButton from "../MachineVehicleMigrationButton";
import ResourceSitesList from "../ResourceSitesList";

interface Props {
  permissions: ResourceDocumentProps["permissions"] & {
    addDocument: Permission;
    editRecord: Permission;
    linkBadge: Permission;
    migrateToMachine: Permission;
  };
}

const VehicleDetailView: FC<Props> = ({ permissions }) => {
  const { id } = useParams();
  const hook = useVehicleViewModel(id as string)
  return hook.loading ? <LoadingView /> : <VehicleDetailViewMain permissions={permissions} hook={hook} />
}

const VehicleDetailViewMain: FC<Props & {hook: ReturnType<typeof useVehicleViewModel>}> = ({ permissions, hook }) => {
  const { id } = useParams();
  const { t } = useTranslation("vehicles");
  const {
    vehicle,
    isFetching,
    updateImage,
    updateVehicle,
    resourceMigrationSites,

    resourceDocumentSites,
    isFetchingResourceDocumentSites,
    resourceDocumentSitesHasNextPage,
    resourceDocumentSitesFetchNextPage,
    updateFilterResourceDocumentSites,
    filterResourceDocumentSites,
    setSortResourceDocumentSites,
    sortResourceDocumentSites,
    totalResourceDocumentSiteCount,

    resourceSites,
    isFetchingSites,
    hasNextPage,
    fetchNextPage,
    setEnableGetVehicleSites,
    filterResourceSites,
    updateFilterResourceSites,
    setSortResourceSites,
    sortResourceSites,
    hasSites,
    setEnableGetVehicleCollection,
    siteCollection,
    siteCollectionIsLoading,
    sortSiteCollection,
    setSortSiteCollection,
    filterSiteCollection,
    setFilterSiteCollection,
    updateFilterSiteCollection,
    siteCollectionHasNextPage,
    siteCollectionFetchNextPage,
    refetchSiteCollection,
    linkResourceToSites,
    isLinking,
    badgeIds,
    setBadgeIds,
    setBadgeId,
    selectAll,
    setSelectAll,
    availableBadgesRefetch,
    availableBadgesFilters,
    availableBadgesIsLoading,
    availableBadgesResource,
    availableBadgesSort,
    linkBadgesToResource,
    unlinkBadgeResourceIsLoading,
    linkBadgesToResourceIsLoading,
    setAvailableBadgesSort,
    setShowInfoSuccess,
    showInfoSuccess,
    unlinkBadgeResource,
    updateFilterAvailableBadge,
    machineVehicleMigrationProps,
    setFilterResourceSites,
    resourceLinkableSitesCount,
    customFields,
    setGetMigrationVehicleSites
  } = hook

  const documentsHook = useVehicleDocumentsViewModel(id);
  const downloader = useDocumentFilesDownloader();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [showAddDocumentType, setShowAddDocumentType] =
    useState<boolean>(false);
  const [showCommunicationModal, setShowCommunicationModal] = useState(false);
  const [showManageTaskWithAI, setShowManageTaskWithAI] =
    useState<boolean>(false);
  const [uploadedImageFile, setUploadedImageFile] = useState<File>();
  const [showSiteCollection, setShowSiteCollection] = useState(false);
  const [selectedSiteIds, setSelectedSiteIds] = useState<string[]>();
  const [linkBadgeModal, setLinkBadgeModal] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const navigate = useNavigate();

  const sanitizedHtmlContent = DOMPurify.sanitize(
    t("addResourceToSites", {
      ns: "sites",
      resource: `${vehicle?.name}`,
    })
  );
  const sanitizedHtmlContentLinkBadges = DOMPurify.sanitize(
    t("linkToBadges", {
      ns: "badges",
      resource: `${vehicle?.name}`,
    })
  );
  const sanitizedHtmlContentUnlinkBadges = DOMPurify.sanitize(
    t("unlinkResourceFromBadge", {
      ns: "badges",
      resource: `${vehicle?.name}`,
    })
  );

  const goToVehiclesList = () => {
    navigate(`/resources/vehicles`);
  };
  const goToSiteConnection = (siteId: string) => {
    navigate(`/sites/${siteId}/resources/vehicles/${id}`)
  }
  const onSubmit: SubmitHandler<Vehicle> = (data) => {
    if (uploadedImageFile) {
      updateImage(
        { ...vehicle, ...data },
        uploadedImageFile
      );
    } else {
      updateVehicle({
        ...vehicle,
        ...data
      });
    }
    setIsEditing(false);
  };

  const methods = useForm<Vehicle>({ mode: "all", defaultValues: vehicle });
  const requiredRule = { required: t("requiredField", { ns: "common" }) };
  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  useEffect(() => {
    if (!linkBadgesToResourceIsLoading) {
      setLinkBadgeModal(false);
    }
  }, [linkBadgesToResourceIsLoading]);

  useEffect(() => {
      documentsHook.communicationProps.refetchResourceDocumentComments();
  }, []);

  const refetchCommentsAndNotes = () => {
      documentsHook.communicationProps.refetchResourceDocumentComments();
      documentsHook.communicationProps.refetchResourceDocumentNotes();
      documentsHook.communicationProps.refetchNotificationStatus();
  };

  if (!vehicle) {
    return <LoadingView />;
  }

  const handleLinkToSites = async () => {
    await linkResourceToSites(selectAll ? [] : selectedSiteIds, selectAll);
    setFilterSiteCollection(null);
    setShowSiteCollection(false);
    setSelectedSiteIds([]);
  };

  return (
    <ContentLayout
      action={
        <ActionBar>
          <ActionBarItem
            bgColor={COLORS.sikuroBlue}
            color="white"
            icon={MdClose}
            description={t("close", { ns: "common" })}
            onClick={goToVehiclesList}
          />

          <RenderIf permissions={permissions.editRecord}>
            <ActionBarItem
              icon={isEditing ? MdCancel : MdEdit}
              onClick={() => {
                setIsEditing(!isEditing);
                if (isEditing) {
                  setUploadedImageFile(undefined);
                }
              }}
              description={t(isEditing ? "cancel" : "edit", { ns: "common" })}
            />
          </RenderIf>
          {isEditing && isValid && (
            <ActionBarItem
              description={t("confirm", { ns: "common" })}
              icon={FaRegSave}
              onClick={handleSubmit(onSubmit)}
            />
          )}
          <RenderIf permissions={permissions.documents.chat}>
            <ActionBarItem
              bgColor={COLORS.yellow}
              color="black"
              icon={HiOutlineChatAlt2}
              description={t("chat", { ns: "communication" })}
              onClick={() => {
                refetchCommentsAndNotes(), setShowCommunicationModal(true);
              }}
            />
          </RenderIf>
          <ActionBarItem
            bgColor={COLORS.yellow}
            color="black"
            icon={LuPackagePlus }
            description={t("addResourceToSitesTooltip", {
              ns: "sites",
              resource: `${vehicle.name}`,
            })}
            onClick={() => {
              setEnableGetVehicleCollection(true);
              setShowSiteCollection(true);
              refetchSiteCollection();
            }}
          />
          <RenderIf permissions={permissions.linkBadge}>
            <ActionBarItem
              icon={BiLink}
              description={t("linkToBadgesTooltip", {
                ns: "badges",
                resource: `${vehicle.name}`,
              })}
              onClick={async () => {
                await availableBadgesRefetch();
                setLinkBadgeModal(true);
              }}
            />
          </RenderIf>
          <RenderIf permissions={permissions.migrateToMachine}>
            <Box onClick={()=> setGetMigrationVehicleSites(true)}>
              <MachineVehicleMigrationButton
                namespace="vehicles"
                hasSites={resourceMigrationSites.length > 0}
                {...machineVehicleMigrationProps}
              />
            </Box>
          </RenderIf>
        </ActionBar>
      }
    >
      {vehicle && (
        <Box px={3} py={5} marginLeft={7}>
          <Box textStyle="h1" mb={2}>
            {vehicle?.name} {vehicle?.model}
          </Box>
          <Box
            pb={8}
            border="1px solid"
            borderColor="gray.300"
            borderRadius="10px"
          >
            <FormProvider {...methods}>
              <DataBox
                title={t("details", { ns: "common" })}
                isEditing={isEditing}
                isLoading={isFetching}
                image={{
                  url: uploadedImageFile
                    ? URL.createObjectURL(uploadedImageFile)
                    : vehicle.photo,
                  onUpdate: (f) => setUploadedImageFile(f),
                }}
                fields={[
                  <FormTextField
                    key="name"
                    name="name"
                    label={t("name")}
                    rules={requiredRule}
                  />,
                  <FormTextField
                    key="plate"
                    name="plate"
                    label={t("plate")}
                    rules={requiredRule}
                  />,
                  <FormTextField key="model" name="model" label={t("model")} />,
                ]}
                customFields={customFields.data}
              />
            </FormProvider>
          </Box>
          <Box
            padding={5}
            border="1px solid"
            borderColor="gray.300"
            borderRadius="10px"
            mt={5}
          >
            <Tabs height="100%" defaultIndex={0} marginTop={4} isFitted>
              <TabList>
                <Tab>{t("documents", { ns: "common" })}</Tab>
                <Tab onClick={()=> setEnableGetVehicleSites(true)}>{t("sites", { ns: "sites" })}</Tab>
              </TabList>
              <TabPanels>
                <TabPanel key={"documents"} paddingLeft={0} paddingRight={0}>
                  <ResourceDocumentsView
                    category={DocumentTypeCategory.VEHICLE}
                    documentsHook={documentsHook}
                    resourcePath="/resources/workers"
                    showAddDocumentType={showAddDocumentType}
                    setShowAddDocumentType={setShowAddDocumentType}
                    showManageTaskWithAI={showManageTaskWithAI}
                    setShowManageTaskWithAI={setShowManageTaskWithAI}
                    downloader={downloader}
                    resourceSites={resourceDocumentSites}
                    hasSites={hasSites}
                    isFetchingSites={isFetchingResourceDocumentSites}
                    hasNextPage={resourceDocumentSitesHasNextPage}
                    fetchNextPage={() => resourceDocumentSitesFetchNextPage()}
                    siteCount={totalResourceDocumentSiteCount}
                    filterResourceSites={filterResourceDocumentSites}
                    updateFilterResourceSites={updateFilterResourceDocumentSites}
                    setSortResourceSites={setSortResourceDocumentSites}
                    sortResourceSites={sortResourceDocumentSites}
                    permissions={permissions}
                    resourceId={vehicle.id}
                    setFilterResourceSites={setFilterResourceSites}
                  />
                </TabPanel>
                <TabPanel key={"site"} paddingLeft={0} paddingRight={0}>
                  <ResourceSitesList
                    sites={resourceSites}
                    sitesFilter={filterResourceSites}
                    updateSitesFilter={updateFilterResourceSites}
                    setSitesSort={setSortResourceSites}
                    sitesSort={sortResourceSites}
                    sitesLoading={isFetchingSites}
                    getSitesHasNextPage={hasNextPage}
                    getSitesFetchNextPage={fetchNextPage}
                    showBadgeColumns
                    navigateAction={(siteId)=> goToSiteConnection(siteId)}
                    showUnlink={true}
                    unlinkBadgeAction={(badgeId) => {
                      setBadgeId(badgeId);
                      setShowDeleteAlert(true);
                    }}
                  ></ResourceSitesList>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Box>
      )}
      {showCommunicationModal && (
        <DocumentCommunicationModal
          onClose={() => setShowCommunicationModal(false)}
          hook={documentsHook.communicationProps}
          permissions={permissions.documents}
        />
      )}
      {showSiteCollection && (
        <BaseModal
          closeOnOverlayClick={false}
          onClose={() => setShowSiteCollection(false)}
          footer={[
            <Button
              key="confirm"
              isDisabled={!selectedSiteIds || selectedSiteIds?.length === 0}
              colorScheme="blue"
              onClick={() => handleLinkToSites()}
              isLoading={isLinking}
            >
              {t("confirm", { ns: "common" })}
            </Button>,
            <Button
              key="cancel"
              colorScheme="red"
              onClick={() => {
                setFilterSiteCollection(null);
                setShowSiteCollection(false);
              }}
            >
              {t("cancel", { ns: "common" })}
            </Button>,
          ]}
        >
          <Text
            fontSize={16}
            marginTop={3}
            marginBottom={5}
            dangerouslySetInnerHTML={{ __html: sanitizedHtmlContent }}
          />
          <SelectSitesView
            autosize
            title={""}
            siteSelectedAction={setSelectedSiteIds}
            siteList={siteCollection}
            isFetchingSites={siteCollectionIsLoading}
            setSortResourceSites={setSortSiteCollection}
            sortResourceSites={sortSiteCollection}
            filterResourceSites={filterSiteCollection}
            updateFilterResourceSites={updateFilterSiteCollection}
            hasNextPage={siteCollectionHasNextPage}
            fetchNextPage={() => siteCollectionFetchNextPage()}
            includeFooterButton={false}
            setSelectAllSites={setSelectAll}
            selectAllSites={selectAll}
            siteCount={resourceLinkableSitesCount}
            showSelectAll={true}
            hideSubtitles={true}
          />
        </BaseModal>
      )}
      {linkBadgeModal && (
        <BaseModal
          closeOnOverlayClick={false}
          onClose={() => setLinkBadgeModal(false)}
          title={
            <Text
              fontWeight={"normal"}
              fontSize={20}
              dangerouslySetInnerHTML={{
                __html: sanitizedHtmlContentLinkBadges,
              }}
            />
          }
          footer={[
            <Button
              key="confirm"
              isDisabled={!badgeIds || badgeIds?.length === 0}
              colorScheme="blue"
              onClick={async () => {
                await linkBadgesToResource();
              }}
              isLoading={linkBadgesToResourceIsLoading}
            >
              {t("confirm", { ns: "common" })}
            </Button>,
            <Button
              key="cancel"
              colorScheme="red"
              onClick={() => setLinkBadgeModal(false)}
            >
              {t("cancel", { ns: "common" })}
            </Button>,
          ]}
        >
          <SelectBadgesTable
            autosize
            key="step3"
            badges={availableBadgesResource}
            badgesFilters={availableBadgesFilters}
            badgesSort={availableBadgesSort}
            setBadgesSort={setAvailableBadgesSort}
            updateBadgesFilter={updateFilterAvailableBadge}
            badgeSitesIsLoading={availableBadgesIsLoading}
            setBadgeIds={setBadgeIds}
            badgeIds={badgeIds}
            selectAllAvailable={false}
          />
        </BaseModal>
      )}
      {showInfoSuccess && (
        <Alert
          title={t("warning", { ns: "common" })}
          message={t(showInfoSuccess, { ns: showInfoSuccess === 'resourceAssociatedToSite' ? "siteResources" : "badges"})}
          variant="info"
          onClose={() => {
            setShowInfoSuccess(null);
          }}
        />
      )}
      <DeleteActionAlert
        mainTitle={t("warning", { ns: "common" })}
        title={
          <Text
            fontWeight={"normal"}
            fontSize={20}
            dangerouslySetInnerHTML={{
              __html: sanitizedHtmlContentUnlinkBadges,
            }}
          />
        }
        onConfirm={() => {
          unlinkBadgeResource(), setShowDeleteAlert(false);
        }}
        isOpen={showDeleteAlert}
        isLoading={unlinkBadgeResourceIsLoading}
        onCancel={() => setShowDeleteAlert(false)}
      />
    </ContentLayout>
  );
};
export default VehicleDetailView;
