import { Text, View } from "@react-pdf/renderer";
import Badge, {
  WorkerMetas,
} from "../../../../domain/entities/badge";
import { formatStringToDate } from "../../../../utils";
import { styles } from "./badgeStyles";
import FrontBadgeContainer from "./FrontBadgeContainer";
import workerPlaceholder from "../../../../assets/images/worker.png";
import { useTranslation } from "react-i18next";

type FrontBadgeWorkerProps = {
  badge: Badge;
};

const FrontBadgeWorker = ({ badge }: FrontBadgeWorkerProps) => {
  const { t } = useTranslation("badges")
  const {
    firstName,
    lastName,
    fiscalCode,
    jobTitle,
    dateOfBirth,
    dateOfEmployment,
    photo
  } = badge.resource.meta as WorkerMetas;

  return (
    <FrontBadgeContainer badge={badge} photo={photo || workerPlaceholder}>
      <Text style={styles.resourceName}>{firstName}</Text>
      <Text style={styles.resourceName}>{lastName}</Text>
      <View style={styles.dashedLine}>
        {Array.from({ length: 60 }).map((_, index) => (
          <View key={index} style={styles.dash} />
        ))}
      </View>
      {jobTitle && (
        <View style={styles.badgeDetailsRow}>
          <Text style={styles.detailTitle}>{t("pdfBadgeDetails.worker.role")}</Text>
          <Text style={styles.text}>{jobTitle}</Text>
        </View>
      )}
      <View style={styles.badgeDetailsRow}>
        <Text style={styles.detailTitle}>{t("pdfBadgeDetails.worker.company")}</Text>
        <Text style={styles.text}>{badge.resource.companyName}</Text>
      </View>
      {dateOfBirth && (
        <View style={styles.badgeDetailsRow}>
          <Text style={styles.detailTitle}>{t("pdfBadgeDetails.worker.dateOfBirth")}</Text>
          <Text>{formatStringToDate(dateOfBirth)}</Text>
        </View>
      )}
      <View style={styles.badgeDetailsRow}>
        <Text style={styles.detailTitle}>{t("pdfBadgeDetails.worker.fiscalCode")}</Text>
        <Text>{fiscalCode}</Text>
      </View>
      {dateOfEmployment && (
        <View style={styles.badgeDetailsRow}>
          <Text style={styles.detailTitle}>{t("pdfBadgeDetails.worker.hireDate")}</Text>
          <Text>{formatStringToDate(dateOfEmployment)}</Text>
        </View>
      )}
    </FrontBadgeContainer>
  );
};
export default FrontBadgeWorker;
