import { Font, StyleSheet } from '@react-pdf/renderer';
import { COLORS } from '../../../assets/theme/colors';

Font.register({
	family: 'Montserrat',
  fonts: [
    { src: '/fonts/Montserrat-Bold.ttf', fontWeight: 900 },
    { src: '/fonts/Montserrat-SemiBold.ttf', fontWeight: 600 },
    { src: '/fonts/Montserrat-Regular.ttf', fontWeight: 400 },
    { src: '/fonts/Montserrat-Light.ttf', fontWeight: 300 },
  ],
});

Font.register({
  family: 'Roboto',
  fonts: [
    { src: '/fonts/Roboto-Regular.ttf', fontWeight: 400 },
    { src: '/fonts/Roboto-Bold.ttf', fontWeight: 700 },
  ],
});

export const styles = StyleSheet.create({
  badgeContainer: {
		border: '1px solid black',
		width: '130mm',
    height: '80mm',
    fontSize: 8,
    fontWeight: 400,
		fontFamily: 'Montserrat',
  },
  badgeHeaderContainer: {
    width: '100%',
    paddingRight: '5.5mm',
    paddingLeft: '5.5mm',
    paddingTop: '3mm',
    paddingBottom: '3mm',
    height: '25mm',
    color: COLORS.white,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: COLORS.sikuroBlue,
  },
  badgeHeader: {
    fontSize: 10,
    fontWeight: 900,
    marginRight: '1mm',
  },
  companyNameContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: "row",
    marginVertical: '3mm'
  },
  mainContainer: {
    width: '100%',
    marginBottom: '6mm',
		flexDirection: 'row',
	},
	photoContainer: {
		width: '30%',
    height: '50mm',
    alignItems: 'center',
		marginRight: '8mm',
  },
  photo: {
		width: '35mm',
		height: '40mm',
		objectFit: 'cover',
  },
  badgeNumber: {
    marginTop: '4mm',
    fontSize: 10,
    fontWeight: 900,
  },
  resourceName: {
    fontSize: 12,
    fontWeight: 900,
    textTransform: 'uppercase',
  },
  badgeDetailsContainer: {
    flexDirection: 'column',
    width: "70%",
    minHeight: '30mm',
    height: "100%",
    marginTop: '4mm',
  },
  dashedLine: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    height: "0.5mm",
    marginTop: '1.5mm',
    marginBottom: '1.5mm',
  },
  dash: {
    width: "0.5mm",
    height: "0.5mm",
    borderRadius: "50%",
    backgroundColor: '#eece23',
    marginRight: "0.5mm",
  },
  badgeDetailsRow: {
    fontSize: 10,
    flexDirection: "row",
    flexWrap: "wrap",
	},
  detailTitle: {
    fontWeight: 900,
    marginRight: '1mm',
    color: COLORS.sikuroBlue,
  },
  text: {
    flex: 1,
  },
  logoContainer: {
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  logo: {
    objectFit: 'cover',
    width: '8mm',
    height: '8mm',
  },
  footerBanner: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '16mm',
    paddingTop: '3mm',
    paddingBottom: '3mm',
    backgroundColor: COLORS.yellow,
  },
  companyName: {
    fontSize: 10,
    fontWeight: 900,
    textTransform: 'uppercase',
  },
  emptyContainer: {
    width: '85.7mm',
    height: '53.9mm',
  },
  siteImage: {
		width: '40mm',
		height: '40mm',
		objectFit: 'cover',
	},
  qrCodeContainer: {
		width: '100%',
    height: '100%',
    minHeight: '40mm',
		display: 'flex',
    flexDirection: "row",
    gap: '10mm',
		alignItems: 'center',
    justifyContent: 'center',
	},
	qrCodeOnlyContainer: {
		width: '50mm',
    height: '50mm',
    minHeight: '40mm',
		margin: '0 auto',
  },
  qrImage: {
    width: '50mm',
    height: '50mm',
    object: 'contain',
  }
});
